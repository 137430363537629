import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Card,
	Headings,
	HeadingProps,
	CardFooterProps,
	Button,
	Icon,
	IconChevron,
	DataComponent,
	VisualComponent,
	FocusableComponent,
	DataObserveKey,
	cn,
	useHeading,
	useUniqueId,
} from "@siteimprove/fancylib";
import { Trigger, setFocus } from "../../../utils/dom-utils";
import { useDidUpdate } from "../../../utils/hooks";
import { ActionListContext } from "../../tables-and-lists/action-list/action-list";
import * as scss from "./accordion.scss";

export type AccordionProps = {
	children: React.ReactNode;
	expanded?: boolean;
} & DataComponent &
	VisualComponent &
	DataObserveKey;

type AccordionValues = {
	headerId: string;
	contentId: string;
	isExpanded: boolean;
	toggleExpanded: () => void;
	buttonRef?: React.RefObject<HTMLButtonElement>;
};

export const AccordionContext = React.createContext(null as AccordionValues | null);

function useUnwrapNullCtx<T>(ctx: React.Context<T | null>, err: string): T {
	const c = useContext(ctx);
	if (c === null) {
		throw new Error(err);
	}
	return c;
}

export const useAccordionContext = (): AccordionValues =>
	useUnwrapNullCtx(AccordionContext, "useAccordionContext called while not inside Header");

/* eslint-disable @typescript-eslint/no-explicit-any */
const getChildrenOnDisplayName: any = (children: any, displayName: string) => {
	if (typeof children.type === "symbol") {
		return getChildrenOnDisplayName(children.props.children, displayName);
	}
	return React.Children.map(children, (child) =>
		child && child.type.displayName === displayName ? child : null
	);
};

export function Accordion(props: AccordionProps): JSX.Element {
	const { children, expanded, className, "data-component": dataComponent, ...rest } = props;

	const [isExpanded, setExpanded] = useState(expanded ?? false);
	const toggleExpanded = () => setExpanded(!isExpanded);
	const close = React.useCallback(() => setExpanded(false), [isExpanded]);

	useEffect(() => {
		setExpanded(expanded ?? false);
	}, [expanded]);

	const header = getChildrenOnDisplayName(children, "AccordionHeader");
	const body = getChildrenOnDisplayName(children, "AccordionBody");
	const footer = getChildrenOnDisplayName(children, "AccordionFooter");

	const headerId = useUniqueId("accordion-header");
	const contentId = useUniqueId("accordion-content");
	const innerContentId = useUniqueId("inner-accordion-content");

	const buttonRef = useRef<HTMLButtonElement>(null);

	const firstFocusableInAccordionContentRef = React.createRef<HTMLElement>();

	//Keeps track of what triggered the toggle
	const whatTriggered = useRef(Trigger.Mouse);

	useDidUpdate(() => {
		if (isExpanded && firstFocusableInAccordionContentRef.current) {
			setFocus(firstFocusableInAccordionContentRef.current, whatTriggered.current);
		} else if (!isExpanded && whatTriggered.current == Trigger.Keyboard && buttonRef.current) {
			setFocus(buttonRef.current, whatTriggered.current);
		}
	}, [isExpanded]);

	return (
		<Card
			data-component={dataComponent ?? "accordion"}
			className={cn(scss.accordion, className)}
			{...rest}
		>
			<AccordionContext.Provider
				value={{
					headerId,
					contentId,
					isExpanded,
					toggleExpanded,
					buttonRef,
				}}
			>
				{header}
				<div id={contentId} className={scss.content} aria-labelledby={headerId} role="region">
					{isExpanded && (
						<ActionListContext.Provider
							value={{
								id: innerContentId,
								listClassName: scss.menuList,
								role: "menu",
								optionRole: "menuitem",
								firstFocusableRef: firstFocusableInAccordionContentRef,
								openState: {
									isOpen: isExpanded,
									setIsOpen: setExpanded,
									close,
								},
							}}
						>
							{body}
						</ActionListContext.Provider>
					)}
					{isExpanded && footer}
				</div>
			</AccordionContext.Provider>
		</Card>
	);
}

export type AccordionHeaderProps = {
	level?: Headings;
} & Omit<HeadingProps, "alignment" | "tooltipText" | "tooltipPlacement"> &
	FocusableComponent;

function AccordionHeader(props: AccordionHeaderProps): JSX.Element {
	const { level, children, className, ...rest } = props;
	const { headerId, contentId, isExpanded, toggleExpanded, buttonRef } = useAccordionContext();

	const button = (
		<Button
			ref={buttonRef}
			variant="borderless"
			className={isExpanded ? cn(scss.headingButton, scss.expanded) : scss.headingButton}
			aria-expanded={isExpanded}
			aria-controls={contentId}
			onClick={toggleExpanded}
		>
			{children}
			<Icon rotation={isExpanded ? "180" : "0"}>{<IconChevron />}</Icon>
		</Button>
	);

	const defaultHeading = useHeading(level ?? "h2", {
		...rest,
		children: button,
		id: headerId,
		className: cn(scss.heading, className),
	});

	return typeof children === "string" ? (
		defaultHeading
	) : (
		<div {...rest} id={headerId} className={cn(scss.heading, className)}>
			{button}
		</div>
	);
}

AccordionHeader.displayName = "AccordionHeader";
Accordion.Header = AccordionHeader;

export type AccordionBodyProps = {
	children: React.ReactNode;
};

function AccordionBody(props: AccordionBodyProps): JSX.Element {
	return props.children as JSX.Element;
}

AccordionBody.displayName = "AccordionBody";
Accordion.Body = AccordionBody;

export type AccordionFooterProps = CardFooterProps;

export function AccordionFooter(props: AccordionFooterProps): JSX.Element {
	return <Card.Footer {...props} />;
}

AccordionFooter.displayName = "AccordionFooter";
Accordion.Footer = AccordionFooter;
