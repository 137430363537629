import React, { ReactNode, useEffect, createRef, useLayoutEffect, RefObject } from "react";
import { cn } from "@siteimprove/fancylib";
import { stopEvent } from "../../../utils/shorthands";
import { keyCodeToListPosition, KeyCode } from "../../../utils/keyboard-nav-utils";
import { useThrottledState } from "../../../utils/hooks";
import { setFocus, Trigger } from "../../../utils/dom-utils";
import * as scss from "./base-interactive-list.scss";

export interface BaseInteractiveListProps<TItem> {
	items: TItem[];
	/** Override how the item is rendered */
	itemRenderer: (item: TItem, idx: number) => ReactNode;
	/** aria-label to fix `Select box has no description (1.3.1, 3.3.2, 4.1.2)` */
	"aria-label"?: string;
	/** alternative to providing an aria-label */
	"aria-labelledby"?: string;
	onItemSelect: (item: TItem, idx: number) => void;
	/** Provide a string representation of item, used for keyboard navigation */
	stringify?: (item: TItem, idx: number) => string;
	listId?: string;
	listRef?: React.RefObject<HTMLUListElement>;
	listClassName?: string;
	/** Set a max pixel height, which makes the list scrollable */
	maxHeight?: number;
	inactive?: boolean;
	/** Set the HTML attribute "role" of the <ul> element */
	role?: string;
	/** Set the HTML attribute "role" of the <li> elements */
	optionRole?: string;
	/** Set className for the <li> elements inside the list */
	liClassName?: (item: TItem) => string | undefined;
	/** Method to determine if an item is not an option (could be divider or other element instead) */
	isNotAnOption?: (item: TItem) => boolean;
	/** Method to determine if an item is disabled (it's used to add a11y attributes to the item) */
	isDisabled?: (item: TItem) => boolean;
}

export function BaseInteractiveList<TItem>(props: BaseInteractiveListProps<TItem>): JSX.Element {
	const {
		items,
		stringify,
		itemRenderer,
		listId,
		onItemSelect,
		listRef,
		inactive,
		role = "listbox",
		optionRole = "option",
		liClassName,
		listClassName,
		isNotAnOption,
		isDisabled,
	} = props;

	const firstFocusableIdx =
		items.findIndex(
			(i) => !(isNotAnOption && isNotAnOption(i)) && !(isDisabled && isDisabled(i))
		) || 0;
	const [renderIdx, liveIdx, setLiveIdx] = useThrottledState(firstFocusableIdx);

	const itemRefs: RefObject<HTMLLIElement>[] = items.map(() => React.createRef<HTMLLIElement>());
	const highlightedItemRef = renderIdx >= 0 ? itemRefs[renderIdx] : itemRefs[0];

	const ulRef = listRef || createRef<HTMLUListElement>();

	const keyboardSearchString = useAccumulateString({ resetAfterMs: 500 });

	useLayoutEffect(() => {
		updateScrollIfNeeded(ulRef, highlightedItemRef);
	}, [ulRef, highlightedItemRef]);

	// when the list changes, update the highlighted item
	useEffect(() => setLiveIdx(firstFocusableIdx), [firstFocusableIdx]);

	// when the list gets inactive, reset the highlighted item
	useEffect(() => {
		inactive && setLiveIdx(0);
	}, [inactive]);

	return (
		<ul
			data-component="interactive-list"
			id={listId}
			role={role}
			aria-label={props["aria-label"]}
			aria-labelledby={props["aria-labelledby"]}
			className={cn(scss.list, listClassName)}
			ref={ulRef}
			onFocus={(e) => {
				if (e.target.tagName === "UL" && !(e.relatedTarget instanceof HTMLLIElement)) {
					const el = itemRefs[firstFocusableIdx];
					el && el.current && setFocus(el.current, Trigger.Mouse);
				}
			}}
			onKeyDown={(e) => {
				// ignore all handling if inactive
				if (inactive) {
					return;
				}
				handleKeyEvent(e);
			}}
			tabIndex={-1}
		>
			{items.map((item, index) => {
				const noOption = isNotAnOption && isNotAnOption(item);
				const disabled = isDisabled && isDisabled(item);
				const className = liClassName && liClassName(item);
				return (
					<li
						tabIndex={renderIdx === index ? 0 : -1}
						key={index}
						className={cn(scss.listItem, className)}
						onClick={() => onItemSelect(item, index)}
						ref={itemRefs[index]}
						role={noOption ? "none" : optionRole}
						aria-hidden={noOption}
						aria-disabled={disabled}
					>
						{itemRenderer(item, index)}
					</li>
				);
			})}
		</ul>
	);

	function handleKeyEvent(e: React.KeyboardEvent): void {
		function focusIdx(idx: number) {
			const el = itemRefs[idx].current;
			el && setFocus(el);
			setLiveIdx(idx);
			stopEvent(e);
		}

		const code = e.keyCode;

		switch (code) {
			case KeyCode.Escape:
				setLiveIdx(0);
				return;
			case KeyCode.Enter:
			case KeyCode.Space:
				// ENTER or SPACE
				onItemSelect && onItemSelect(items[liveIdx.current], liveIdx.current);
				stopEvent(e);
				return;
		}

		const focusableItems = items.filter(
			(i) => !(isNotAnOption && isNotAnOption(i)) && !(isDisabled && isDisabled(i))
		);
		const newIdx = keyCodeToListPosition(
			"vertical",
			e.keyCode,
			focusableItems.indexOf(items[liveIdx.current]),
			focusableItems.length - 1
		);

		if (newIdx !== null) {
			focusIdx(items.indexOf(focusableItems[newIdx]));
			return;
		}

		if (e.key.length === 1) {
			const searchStr = keyboardSearchString(e.key);
			const rgx = new RegExp(`^${searchStr}`, "i"); //case insensitive startsWith
			const idx = items.findIndex((item, idx) => {
				const str = typeof item === "string" ? item : stringify ? stringify(item, idx) : null;
				return str && rgx.test(str);
			});
			if (idx !== -1) {
				focusIdx(idx);
				return;
			}
		}
	}
}

function updateScrollIfNeeded(
	list: React.RefObject<HTMLUListElement>,
	item: React.RefObject<HTMLLIElement>
): void {
	if (!list?.current || !item?.current) {
		return;
	}

	const itemEl = item.current;
	const listEl = list.current;
	const itemRect = itemEl.getBoundingClientRect();
	const listRect = listEl.getBoundingClientRect();

	// list item is above scroll view
	if (itemEl.offsetTop < listEl.scrollTop) {
		listEl.scrollTop = itemEl.offsetTop;
	}

	// list item is below scroll view
	else if (itemEl.offsetTop + itemRect.height > listEl.scrollTop + listRect.height) {
		listEl.scrollTop = itemEl.offsetTop + itemRect.height - listRect.height;
	}
}

function useAccumulateString(opts: { resetAfterMs: number }) {
	const ref = React.useRef({ time: Date.now(), value: "" });
	return (toAccumulate: string) => {
		const now = Date.now();
		if (now - ref.current.time > opts.resetAfterMs) {
			ref.current.value = toAccumulate;
		} else {
			ref.current.value = ref.current.value + toAccumulate;
		}
		ref.current.time = now;
		return ref.current.value;
	};
}
