import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Pagination } from "./pagination";
import { PageChanger } from "./page-changer";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Pagination",
	notepad: "https://hackmd.io/ugwMcFGHQgCnqWqDPIBHaQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Pagination"
			subTitle="Enable users to navigate efficiently through large datasets or content spanning multiple pages. Allow customization of the number of items displayed per page."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Pagination} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Pagination is a crucial navigation element for large datasets or lengthy content. It
					breaks information into manageable chunks, preventing overwhelm and improving the user
					experience.
				</Paragraph>
				<Paragraph>Pagination is composed of</Paragraph>
				<Ul
					items={[
						<>
							<b>Total item count</b>: Clearly show the range of currently visible items (e.g.,
							"1-10 of 100").
						</>,
						<>
							<b>Navigation controls</b>:
							<Ul
								items={[
									<>
										<b>Previous/Next buttons</b>: Use clear icons (e.g., chevrons) or text labels.
									</>,
									<>
										<b>First/Last buttons</b> (optional): Include for large datasets to enable quick
										navigation.
									</>,
									<>
										<b>Page number information and input field</b> (optional): Display the current
										page and allow direct input for specific pages (e.g., "Page 3 of 10").
									</>,
								]}
							/>
						</>,
						<>
							<b>Items per page</b> (optional): Allow users to adjust the number of items displayed
							per page.
						</>,
					]}
				/>

				<Header.H2>Basic Usage</Header.H2>
				<Example fn={BasicUsage} />

				<Header.H2>Page Changer</Header.H2>
				<Example fn={PageChangerUsage} />

				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Pagination}
					initialProps={({ setState }) => ({
						count: 5,
						total: 25,
						page: 1,
						setPage: (page) => setState({ page: page }),
						pageSize: 5,
						setPageSize: (pageSize) => setState({ pageSize: pageSize }),
						cancelLabel: "Cancel",
						confirmLabel: "Confirm",
						firstLabel: "First",
						prevLabel: "Previous",
						nextLabel: "Next",
						lastLabel: "Last",
						pagingInfoLabel: (startIdx: number, endIdx: number, total: number) =>
							`${startIdx} - ${endIdx} of ${total} items`,
						pageLabel: "Page",
						pageXofYLabel: (current: number, total: number) => `Page ${current} of ${total}`,
						pageSizeSelectionLabel: (pageSize: number) => `${pageSize} items`,
						pageSizeSelectorPrefix: "Show",
						pageSizeSelectorPostfix: "per page",
						pageSizeLabel: "Items per page",
						defaultError: "Default pagination error",
						wholeNumberError: "Must be a whole number",
						outOfBoundsError: (total: number) => `Enter a number between 1 and ${total}`,
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Pagination</Code> when
					</Paragraph>
					<Ul
						items={[
							"The amount of information exceeds a single page's comfortable display capacity.",
							"Users need to locate specific items within a larger collection.",
							"You want to enhance the perceived loading speed of a page by breaking it into smaller chunks.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Pagination</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Below content</b>: The most common and intuitive location.
							</>,
							<>
								<b>Within Data Tables/Lists</b>: Enhance navigation within structured data.
							</>,
							<>
								{" "}
								<b>Search results</b>: Facilitate browsing through multiple pages of results.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Pagination</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Loading states</b>: Use visual cues (e.g.,{" "}
								<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink>) to indicate
								when pages are loading.
							</>,
							<>
								<b>Empty states</b>: Display a friendly message when no results are found on a page
								(e.g., "No results found for this page.").
							</>,
							<>
								<b>Error states</b>: If pagination fails, provide a clear error message (e.g{" "}
								<GatsbyLink to="/lab/components/Feedback/Toast">Toast</GatsbyLink>) and offer
								solutions (e.g., "An error occurred while loading this page. Please try again
								later.").
							</>,
							<>
								<b>Items per page</b>: Provide reasonable default options based on content type.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The dataset is small enough to fit on one page.",
							"The content is best presented as infinite scrolling.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={[<>Ensure sufficient contrast between text/icons and background.</>]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Header.H4>Labels</Header.H4>
					<Ul
						items={[
							<>Use "Previous" and "Next" for basic navigation.</>,
							<>Consider "First" and "Last" for larger datasets.</>,
							<>Avoid ambiguous terms like "More" or "Load More."</>,
						]}
					/>
					<Header.H4>Page Numbers</Header.H4>
					<Ul
						items={[
							<>Clearly indicate the current page.</>,
							<>Allow direct input for specific page numbers in large datasets.</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const allData = Array.from(Array(1000).keys());
	const pagedData = allData.slice((page - 1) * pageSize, page * pageSize);

	return (
		<Pagination
			count={pagedData.length}
			total={allData.length}
			page={page}
			setPage={setPage}
			pageSize={pageSize}
			setPageSize={setPageSize}
			cancelLabel="Cancel"
			confirmLabel="Confirm"
			firstLabel="First"
			prevLabel="Previous"
			nextLabel="Next"
			lastLabel="Last"
			pagingInfoLabel={(startIdx: number, endIdx: number, total: number) =>
				`${startIdx} - ${endIdx} of ${total} items`
			}
			pageLabel="Page"
			pageXofYLabel={(current: number, total: number) => `Page ${current} of ${total}`}
			pageSizeSelectionLabel={(pageSize: number) => `${pageSize} items`}
			pageSizeSelectorPrefix="Show"
			pageSizeSelectorPostfix="per page"
			pageSizeLabel="Items per page"
			defaultError="Default pagination error"
			wholeNumberError="Must be a whole number"
			outOfBoundsError={(total: number) => `Enter a number between 1 and ${total}`}
			ariaLabel="Pagination"
		/>
	);
};

const PageChangerUsage = () => {
	const [page, setPage] = useState(1);

	return (
		<PageChanger
			total={8}
			page={page}
			setPage={setPage}
			pageSize={5}
			cancelLabel="Cancel"
			confirmLabel="Confirm"
			firstLabel="First"
			prevLabel="Previous"
			nextLabel="Next"
			lastLabel="Last"
			pageLabel="Page"
			pageXofYLabel={(current: number, total: number) => `Page ${current} of ${total}`}
			defaultError="Default pagination error"
			wholeNumberError="Must be a whole number"
			outOfBoundsError={(total: number) => `Enter a number between 1 and ${total}`}
		/>
	);
};
