import React, { useEffect, useState } from "react";
import eq from "fast-deep-equal";
import {
	Content,
	Divider,
	H3,
	Icon,
	IconFunnel,
	InlineText,
	useTranslations,
	cn,
} from "@siteimprove/fancylib";
import { Popover, PopoverProps } from "../../overlay/popover/popover";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { FormElementWrapper } from "../../forms-and-inputs/form-element-wrapper/form-element-wrapper";
import { useLabTranslations } from "../../../translations/translations";
import { Select, SelectValue } from "../../forms-and-inputs/select/select";
import { dataObserveKeyDiscriminator } from "../../../utils/shorthands";
import { BaseFilterProps } from "./single-filter";
import * as scss from "./filter-group.scss";

type FilterGroupContextValue = {
	value: unknown;
	onChange: (newValue: unknown) => void;
};

const FilterGroupContext = React.createContext<FilterGroupContextValue>({
	value: null,
	onChange: () => {},
});

export type FilterGroupProps<T> = {
	value: T;
	onChange: (newValue: T) => void;
	emptyState: T;
	filters: FilterItemProps<T>[];
} & Partial<PopoverProps>;

export type FilterItemProps<T> = {
	[K in keyof T]: Omit<BaseFilterProps<T[K]>, "onChange" | "value"> & {
		property: K;
		stringify: (value: T) => string | undefined;
	};
}[keyof T];

export function FilterGroup<T>(props: FilterGroupProps<T>): JSX.Element {
	const { value, onChange, emptyState, filters, ...rest } = props;
	const [tmpState, setTmpState] = useState<T>(value);
	const i18nLab = useLabTranslations();
	const i18nLib = useTranslations();

	useEffect(() => {
		setTmpState(value);
	}, [value]);

	return (
		<FilterGroupContext.Provider
			value={{
				value: tmpState as unknown,
				onChange: setTmpState as (newValue: unknown) => void,
			}}
		>
			<Popover
				data-component="filter-group"
				popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
					<div id={id} ref={firstFocusableRef}>
						<Content>
							<H3 lookalike="h4">{i18nLab.filterBy}</H3>
						</Content>
						<Divider />
						{filters.map((filter, index) => (
							<div key={filter.name || filter.property.toString()}>
								<FilterItem {...filter} />
								{index < filters.length - 1 && <Divider />}
							</div>
						))}
						<Popover.Footer>
							<ActionBar
								primary={{
									children: i18nLab.apply,
									onClick: () => {
										onChange(tmpState as T);
										setIsOpen(false);
									},
									"data-observe-key": dataObserveKeyDiscriminator(
										props["data-observe-key"],
										"Apply"
									),
									disabled: eq(tmpState, value),
								}}
								secondaries={[
									{
										children: i18nLab.clearAll,
										onClick: () => {
											setTmpState(emptyState);
										},
										"data-observe-key": dataObserveKeyDiscriminator(
											props["data-observe-key"],
											"ClearAll"
										),
									},
								]}
								cancel={{
									children: i18nLib.cancel,
									onClick: () => {
										setTmpState(value);
										setIsOpen(false);
									},
									"data-observe-key": dataObserveKeyDiscriminator(
										props["data-observe-key"],
										"Cancel"
									),
								}}
							/>
						</Popover.Footer>
					</div>
				)}
				buttonContent={
					<>
						<Icon>
							<IconFunnel />
						</Icon>
						<InlineText>{i18nLab.filters}</InlineText>
					</>
				}
				hideChevron
				{...rest}
			/>
		</FilterGroupContext.Provider>
	);
}

function FilterItem<T>(props: FilterItemProps<T>): JSX.Element {
	const { active, label, stringify, property, name, ...selectProps } = props;
	const { value, onChange } = React.useContext(FilterGroupContext);

	const handleOnChange = (newValue: SelectValue<T[keyof T]>) => {
		onChange({ ...(value as T), [property]: newValue } as T);
	};

	return (
		<Content padding="compact">
			<FormElementWrapper
				label={`${label}:`}
				name={name || property.toString()}
				className={cn(scss.formElementWrapper)}
			>
				<Select
					noDefaultOption={selectProps.defaultOption === undefined}
					{...selectProps}
					value={(value as T)[property] as SelectValue<T[keyof T]>}
					onChange={handleOnChange}
				/>
			</FormElementWrapper>
		</Content>
	);
}
