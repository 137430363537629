import React, { useState, useEffect, useMemo } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	TextContainer,
	Paragraph,
	Ul,
	Button,
	Icon,
	IconDownload,
	InlineText,
	IconSearch,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { TableToolbar } from "../table-toolbar/table-toolbar";
import { InputFieldWithSlug } from "../../forms-and-inputs/input-field/input-field";
import { SortField } from "../column-header/column-header";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { CsvColumnWithSubTableConfig, escapeCsvContent } from "../../../utils/csv-exporter";
import { ColumnsArray } from "../base-table/base-table";
import { useSingleFilter } from "../filters/filters";
import { BigSmall } from "../../text/big-small/big-small";
import { Table, TableProps, invertDirection, TableCsvExporter, TableExporter } from "./table";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Table",
	notepad: "https://hackmd.io/fprWJWEfSN-szYSHeMWQ8w",
};

const knobItems = [
	{ id: 1, title: "Number 1" },
	{ id: 2, title: "Number 2" },
	{ id: 3, title: "Number 3" },
	{ id: 4, title: "Number 4" },
];

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Table"
			subTitle="Data tables are used to present a set of highly structured data that is easy for the user to scan, compare and analyze.  "
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Table} />
				<Header.H3> (Data) table v.s List table</Header.H3>
				<Paragraph>
					To clarify the differences between <Code>Table</Code> and <Code>ListTable</Code>, a short
					description is provided.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Component name</th>
							<th>Usage</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>(Data) table</td>
							<td>
								Used to organize and present data in a way that helps the user compare and analyze
								it.
							</td>
						</tr>
						<tr>
							<td>List table</td>
							<td>
								Used to list a collection of objects of the same type, such as policies, to help the
								user find an object and navigate to a full-page representation of it.
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Basic usage</Header.H3>
				<Paragraph>
					You can use a <Code>Table</Code> to structure both static and interactive data, arranged
					in rows and columns. Since the <Code>Table</Code> is designed for use cases that are
					focused on handling large amounts of tabular data, it must contain a{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Column header">Column header</GatsbyLink>{" "}
					for sorting. The component provides tools such as buttons, filters, search and export to
					ensure that UI elements remain consistent and the user is given the flexibility to gain
					relevant insights from the data set.
				</Paragraph>
				<Paragraph>
					A <Code>Table</Code> component contains four elements:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Column header</b>: the labels for each column in the table. The{" "}
							<GatsbyLink to="/lab/components/Tables and lists/Column header">
								Column header
							</GatsbyLink>{" "}
							can sort the data in ascending or descending order. However, the default order of the
							columns should reflect the importance of the data to the user, and related columns
							should be adjacent. See{" "}
							<GatsbyLink to="/lab/components/Tables and lists/Column header">
								Column header
							</GatsbyLink>
							.
						</>,
						<>
							<b>Rows</b>: each row contains the same number of cells and the content related to the
							corresponding{" "}
							<GatsbyLink to="/lab/components/Tables and lists/Column header">
								Column header
							</GatsbyLink>
							. The rows can be expanded and highlighted to emphasize specific data.
						</>,
						<>
							<b>Table Toolbar</b> (optional): a flexible container that provides access to several
							table related functions, such as actions, search, filtering and export. See{" "}
							<GatsbyLink to="/lab/components/Tables and lists/Table toolbar">
								Table Toolbar
							</GatsbyLink>
							.
						</>,
						<>
							<b>Pagination</b> (optional): allow the user to navigate data as pages when the amount
							of data is too large to be displayed at once. See{" "}
							<GatsbyLink to="/lab/components/Navigation/Pagination">Pagination</GatsbyLink>.
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Usage with pagination</Header.H3>
				<Paragraph>
					A <GatsbyLink to="/lab/components/Navigation/Pagination">Pagination</GatsbyLink> allows
					the user to navigate through a collection of items split across multiple pages of data,
					and is displayed below the bottom row.
				</Paragraph>
				<Paragraph>
					A <GatsbyLink to="/lab/components/Navigation/Pagination">Pagination</GatsbyLink> includes:
				</Paragraph>
				<Ul
					items={[
						<>Text to show the total number of items, including the currently displayed items.</>,
						<>
							<GatsbyLink to="/lib/components/Actions and controls/Button">
								Button groups
							</GatsbyLink>{" "}
							to navigate to the first, previous, next and last page.
						</>,
						<>
							A <GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink> to
							specify a page number.{" "}
						</>,
						<>
							A <GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink> to
							specify the number of items per page.{" "}
						</>,
					]}
				/>
				<Example fn={UsageWithPagination} />
				<Header.H3>Usage with search</Header.H3>
				<Paragraph>
					Allow the user to search for specific data that meets specific criteria. To ensure
					consistency, use an input field within the{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Table toolbar">Table Toolbar</GatsbyLink>{" "}
					component.
				</Paragraph>
				<Paragraph>
					The user can perform a table-wide keyword search on all cell values that have been indexed
					for the search. The search function works with the default typeahead functionality of the
					standard search component. When you perform a search query, the table is updated to show
					only those rows whose values match the search term.
				</Paragraph>
				<Example fn={UsageWithSearch} />
				<Header.H3>Usage with filters</Header.H3>
				<Paragraph>
					In addition to searching, the user can use the filter function to narrow and refine the
					rows displayed in the <Code>Table</Code>. To ensure consistency, use filters within the{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Table toolbar">Table Toolbar</GatsbyLink>{" "}
					component.
				</Paragraph>
				<Paragraph>
					Depending on the configuration chosen during implementation, filtering can be applied as
					follows:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Table-wide filtering</b>: allows the user to filter the data by all attributes
							present in any of the data columns.
						</>,
						<>
							<b>Custom column filtering</b>: allows the user to focus the filtering on the
							attributes within a single column of data.
						</>,
					]}
				/>
				<Paragraph>
					Filters that control how <Code>Table</Code> data is displayed should be placed directly
					over a <Code>Table</Code>. The syntax of filters should be transparent to users, and they
					should easily recognize that they are seeing filtered data. Always make sure that filters
					have a clear visual indication of their active state.
				</Paragraph>
				<Example fn={UsageWithFilters} />
				<Header.H3>Usage with expandable content</Header.H3>
				<Paragraph>
					Use expandable content to reduce visual noise and make it easier to read the content that
					is most important for the task at hand. Clicking on the cell expands the row to fill the
					width of the <Code>Table</Code>, and if needed, the contents of the expanded row can be
					accessed further by scrolling. Avoid a lot of interaction and do not take up more than 50%
					of the screen. If you need to expand the page to display dense, highly interactive
					content, use a new page for that purpose instead.
				</Paragraph>
				<Paragraph>Expandable rows serve two purposes for users:</Paragraph>
				<Ul
					items={[
						<>Allow users to view simple and additional information.</>,
						<>Users can reduce the width of the cells for content they consider less important.</>,
					]}
				/>
				<Paragraph>
					Note that data loading can occur the moment the row is expanded. In this case, you can use
					a <GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> to tell the user
					that the content is loading.
				</Paragraph>
				<Example fn={ExpandableUsage} />
				<Header.H3>Usage with highlight</Header.H3>
				<Paragraph>
					The highlighted effect allows the user to focus on a single row at a time, especially when
					you have multiple columns and data points. The highlighted row uses the background color{" "}
					<Code>$color--background--interactive--selected</Code> (<Code>#EBF6FF</Code>
					). You can use this effect to highlight a selected row if the row contains an interactive
					element, such as{" "}
					<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink>.
				</Paragraph>
				<Example fn={UsageWithHighlight} />
				<Header.H3>Usage with data-observe-keys on table cells</Header.H3>
				<Paragraph>
					Use <Code>data-observe-key</Code> on table cells to create an identifier, which is useful
					for tracking user interactivity, for example.
				</Paragraph>
				<Example fn={UsageWithDataObserveKeysOnCells} />
				<Header.H3>Usage with summary</Header.H3>
				<Paragraph>
					Include a summary row that displays column totals and averages. This helps the user to get
					a quick overview of certain data. For example, the Analytics site statistics overview
					displays the percentage of total page views per device/page and the average bounce rate
					for each site.
				</Paragraph>
				<Example fn={UsageWithSummary} />
				<Header.H3>Usage with exporter</Header.H3>
				<Paragraph>
					Using our <Code>TableExporter</Code> component or the <Code>useTableExport</Code> hook,
					developers have access to a <Code>Modal</Code> that allows the user to decide which table
					pages he wants to export (current page or all pages). The user can also choose whether or
					not to export subtables (expanded content). With these user settings, the developer can
					proceed and call his own export routine.
				</Paragraph>
				<Paragraph>
					If you have a <Code>Table</Code> within a <Code>Card</Code>, please display the export
					button explicitly. Do not place the export button inside the <Code>ActionMenu</Code> on
					the right edge of a <Code>Card</Code>. This is because the export button is for
					downloading the table data and not all the information on the <Code>Card</Code>.
					Sometimes, there might be other UI elements in the <Code>Card</Code>, such as{" "}
					<Code>Tab</Code> or <Code>ContentSwitcher</Code> that export data that the user may not
					initially need.
				</Paragraph>
				<Example fn={UsageWithExporter} />
				<Header.H3>Usage with CSV exporter</Header.H3>
				<Paragraph>
					Raw tabular data is data that can be exported to a CSV file. It contains no formatting and
					is identical to the source file.
				</Paragraph>
				<Paragraph>
					The "Export to CSV" button is the most common secondary action for the user. Thus we offer
					a built-in CSV exporter for free through the <Code>TableCsvExporter</Code> component,
					which supports exporting subtables, custom filename, custom header and footer, custom
					delimiter, etc. The CSV representation of each column will be inferred automatically if
					possible, otherwise, developers should define it by using the <Code>csv</Code> property on
					each column config, as we can see in the example code. Subtable settings must always be
					defined by the developer.
				</Paragraph>
				<Example fn={UsageCsvWithExporter} />
				<Header.H3>Combined columns</Header.H3>
				<Paragraph>
					You can combine columns to create a new column that contains the combined data of the
					original columns. This is useful when you want to display related data in a single column,
					but still want to sort and filter the data based on the original columns.
				</Paragraph>
				<Example fn={UsageWithCombinedColumns} />
				<Header.H3>Usage with default sorting</Header.H3>
				<Paragraph>
					You can set a custom default sorting order for when no column sorting is applied. This
					feature is especially useful if you want the data to appear in a specific order initially,
					while still allowing users to sort it in other ways. The default sorting order is
					re-applied when the user cycles through all sorting options (ascending, descending, and
					back to default) by clicking the column header.
				</Paragraph>
				<Example fn={UsageWithDefaultSorting} />
				<Header.H3>Loading state</Header.H3>
				<Paragraph>
					The loading state is used to indicate that the data of the table is still being loaded.
				</Paragraph>
				<Example fn={UsageWithLoadingState} />
				<Header.H3>No data state</Header.H3>
				<Paragraph>
					The <Code>noDataState</Code> is used to indicate that no data is available for display. An{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component
					with the <Code>type</Code> "reassure" and default heading is shown, but it can be
					overridden by another type with custom text. For guidelines please refer to the{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component.
				</Paragraph>
				<Paragraph>
					To avoid confusion, in cases where the user has resolved the issues, explain why the data
					cannot be displayed. E.g. "Accessibility issues have been resolved".
				</Paragraph>
				<Example fn={UsageWithNoDataState} />
				<Header.H2>Properties</Header.H2>
				<Knobs<TableProps<{ id: number; title: string }>>
					component={Table}
					initialProps={({ getPrevState, setState }) => ({
						items: knobItems,
						columns: [
							{
								header: {
									property: "id",
									content: "Id",
									defaultSortDirection: "desc",
								},
								render: (dto) => dto.id,
								options: {
									isKeyColumn: true,
								},
							},
							{
								header: {
									property: "title",
									content: "Name",
									defaultSortDirection: "desc",
								},
								render: (dto) => dto.title,
							},
						],
						sort: { property: "id", direction: "asc" },
						setSort: (
							property: Extract<keyof { id: number; title: string }, string>,
							direction: "asc" | "desc"
						) => {
							const prevSort = getPrevState()?.sort;
							const sort = {
								property: property,
								direction:
									prevSort && property === prevSort.property
										? invertDirection(prevSort.direction)
										: direction,
							};
							setState({
								sort: sort,
								items: sortItems(knobItems, sort),
							});
						},
						loading: false,
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Table</Code> when
					</Paragraph>
					<Ul
						items={[
							<>organizing and displaying data that fills one or more rows.</>,
							<>comparing information from an entire set of data.</>,
							<>a task requires the user to navigate to a specific piece of data.</>,
						]}
					/>
					<Header.H4>Usage</Header.H4>
					<Paragraph>
						<Code>Table</Code> is typically used for the following purposes:
					</Paragraph>
					<Paragraph>
						<b>Finding data that meet specific criteria</b>
					</Paragraph>
					<Paragraph>
						In this type of task, the user searches for a specific item or items that meet certain
						criteria that the user has in mind. In doing so, the user may need to filter, sort,
						search, or simply visually walk through the table. Provide appropriate tools to help the
						user find what they are looking for as easily as possible. Examples of this can be found
						throughout the base data, such as the occurrence of issues, broken links, misspellings
						etc.
					</Paragraph>
					<Paragraph>
						<b>Comparing data</b>
					</Paragraph>
					<Paragraph>
						Tables are most effective when users can compare data, but they can be difficult to
						understand at times. The content in each body cell should be meaningful and clear. Make
						sure that the most relevant columns are close to each other rather than forcing them to
						scroll back and forth memorizing data. Examples can be found in the features catering to
						expert users, such as customizable tables, Ads Data Explorer, SEO Keyword Monitoring
						etc.
					</Paragraph>
					<Header.H4>Rows and columns</Header.H4>
					<Ul
						items={[
							<>
								Ideally, all values have the same visual weight and are emphasized equally. For
								important information that needs to be emphasized more, consider the order in which
								the data is presented.
							</>,
							<>
								If a <Code>Table</Code> is larger than the screen, consider freezing the{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Column header">
									Column header
								</GatsbyLink>{" "}
								to make it easier to find the relevant information.
							</>,
							<>Make sure there is a clear visual cue when a column is hidden.</>,
						]}
					/>
					<Header.H4>Body cells</Header.H4>
					<Ul
						items={[
							<>
								Minimize clutter by including only values that support the purpose of the data. Use
								visual elements such as icons and illustrations sparingly.
							</>,
							<>
								A <Code>Table</Code> can contain interactive elements, such as{" "}
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink> that
								provide multiple selection and bulk action functions. However, you should use only
								one interactive element in each cell.
							</>,
							<>
								For textual or numeric values, each cell can contain only 1 piece of information.
							</>,
							<>
								Cells can contain the{" "}
								<GatsbyLink to="/lib/components/Navigation/Link">Link</GatsbyLink> component, but
								avoid linking entire table rows. This navigation feature does not serve the main
								purpose of a <Code>Table</Code>. If you need to link a whole row, use the{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table">List table</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
					<Header.H4>Alignment</Header.H4>
					<Paragraph>
						Alignment is determined by the information within each column.{" "}
						<GatsbyLink to="/lab/components/Tables and lists/Column header">
							Column header
						</GatsbyLink>{" "}
						should always align to the corresponding content.
					</Paragraph>
					<Ul
						items={[
							<>Numeric data = Right aligned.</>,
							<>Textual data = Left aligned.</>,
							<>If there is an action, it should be on the far right of the table row.</>,
							<>Do not center align data.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Do not truncate content that the user needs to examine in relation to other content
								in a <Code>Table</Code>.
							</>,
							<>Avoid extensively wrapping the table content in a narrow column.</>,
							<>
								Avoid embedding{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form element wrapper
								</GatsbyLink>{" "}
								within table rows.
							</>,
							<>
								Avoid leaving cells blank so that it is not clear whether all data has been loaded.
								Include a visual indicator for cells that have no content.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								there's less than 1 row of tabular data. For a small amount of data, use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table">List table</GatsbyLink>{" "}
								or <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> instead.
							</>,
							<>
								the content does not follow a consistent pattern and cannot be divided into columns.
							</>,
							<>
								data is provided that does not fit into a tabular format. If you want to display a
								more complex data relationship, use data visualization, such as{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink>{" "}
								instead.
							</>,
							<>
								you want an action-oriented list of items that link to detail pages. For this
								functionality, use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table">List table</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H3>For designers</Header.H3>
					<Ul
						items={[
							<>Avoid including more than one interactive element in as single cell.</>,
							<>
								For people using screen magnification, the selected{" "}
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink> may
								appear outside the magnified screen area. Adding a background color provides an
								additional way to indicate that a row has been selected. See{" "}
								<a href="#usage-with-highlight">Usage with highlight</a>.
							</>,
							<>
								The focus state should be displayed when keyboard users tab through interactive
								components, such as{" "}
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink>.
							</>,
						]}
					/>
					<Header.H3>For developers</Header.H3>
					<Ul
						items={[
							<>
								If possible, avoid refreshing the entire page when making changes to a table (e.g.
								sorting, filtering, removing rows). This returns the focus of the keyboard or screen
								reader to the top of the page, making it very tedious for the users.
							</>,
							<>
								Make sure all columns have a meaningful{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Column header">
									Column header
								</GatsbyLink>
								, even if it is visually hidden.
							</>,
							<>
								Be sure to give a <Code>Table</Code> a short, meaningful caption. For example, use
								the same text as a <Code>Table</Code> heading element. This helps assistive
								technology users understand the purpose and context of the table.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>All content should be informative, clear, and concise.</>,
							<>Leave enough space for content to accommodate localization, if possible.</>,
							<>
								Include symbols for units of measure (%) in the{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Column header">
									Column header
								</GatsbyLink>{" "}
								so they are not repeated in the body cells.
							</>,
							<>Use sentence case.</>,
							<>
								Keep decimal numbers consistent, preferably use 2 decimal places. Do not use 1
								decimal in one row and 2 in another.
							</>,
							<>
								In most cases, punctuation is not required within tables unless the data contains
								numbers and units. Follow{" "}
								<GatsbyLink to="/Writing/grammar-and-mechanics">Grammar and mechanics</GatsbyLink>.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const someData = [
	{ id: 1, title: "Lasagna", cookTime: 45, servings: 2 },
	{ id: 2, title: "Pancakes", cookTime: 20, servings: 4 },
	{ id: 3, title: "Sushi", cookTime: 90, servings: 6 },
	{ id: 4, title: "Cake", cookTime: 30, servings: 8 },
];

const BasicUsage = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
						"data-observe-key": "table-header-dish",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						content: "Cook Time",
						tooltip: "in minutes",
						"data-observe-key": "table-header-cook-time",
					},
					render: (dto) => dto.cookTime,
				},
				{
					header: {
						property: "servings",
						content: "Servings",
						tooltip: "in persons",
						notSortable: true,
						"data-observe-key": "table-header-servings",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			caption="Basic usage table"
		/>
	);
};

const UsageWithPagination = () => {
	const lotsOfData: { id: number; title: string; cookTime: number; servings: number }[] = [];
	for (let i = 1; i < 16; i++) {
		lotsOfData.push({
			id: i,
			title: `Recipe ${i}`,
			cookTime: 5 * i,
			servings: Math.floor(i / 25) + 1,
		});
	}
	const [sort, setSort] = useState<SortField<typeof lotsOfData[0]>>({
		property: "title",
		direction: "asc",
	});
	const [items, setItems] = useState(sortItems(lotsOfData, sort));
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const pagedItems = items.slice((page - 1) * pageSize, page * pageSize);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						property: "cookTime",
						content: "Cook Time",
					},
					render: (dto) => dto.cookTime,
				},
				{
					header: {
						property: "servings",
						content: "Servings",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={pagedItems}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			pagination={{
				total: items.length,
				count: pagedItems.length,
				page: page,
				setPage: setPage,
				pageSize: pageSize,
				setPageSize: setPageSize,
				cancelLabel: "Cancel",
				confirmLabel: "Confirm",
				firstLabel: "First",
				prevLabel: "Previous",
				nextLabel: "Next",
				lastLabel: "Last",
				pagingInfoLabel: (startIdx: number, endIdx: number, total: number) =>
					`${startIdx} - ${endIdx} of ${total} items`,
				pageLabel: "Page",
				pageXofYLabel: (current: number, total: number) => `Page ${current} of ${total}`,
				pageSizeSelectionLabel: (pageSize: number) => `${pageSize} items`,
				pageSizeSelectorPrefix: "Show",
				pageSizeSelectorPostfix: "per page",
				pageSizeLabel: "Items per page",
				defaultError: "Default pagination error",
				wholeNumberError: "Must be a whole number",
				outOfBoundsError: (total: number) => `Enter a number between 1 and ${total}`,
			}}
		/>
	);
};

const UsageWithSearch = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [query, setQuery] = useState("");
	const displayedItems = items.filter((x) => x.title.toLowerCase().startsWith(query.toLowerCase()));
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<>
			<TableToolbar
				search={
					<InputFieldWithSlug
						value={query}
						onChange={setQuery}
						placeholder="Search by dish title"
						rightSlug={
							<Button onClick={() => console.log(query)} aria-label="Submit search">
								<Icon>
									<IconSearch />
								</Icon>
							</Button>
						}
					/>
				}
			/>
			<Table
				columns={[
					{
						header: {
							property: "title",
							content: "Dish",
							defaultSortDirection: "asc",
						},
						render: (dto) => dto.title,
						options: {
							isKeyColumn: true,
						},
					},
					{
						header: {
							property: "cookTime",
							content: "Cook Time",
						},
						render: (dto) => dto.cookTime,
					},
					{
						header: {
							property: "servings",
							content: "Servings",
						},
						render: (dto) => dto.servings,
					},
				]}
				items={displayedItems}
				sort={sort}
				setSort={(property, direction) => {
					setLoading(true);
					setSort({
						property: property,
						direction: property === sort.property ? invertDirection(sort.direction) : direction,
					});
				}}
				loading={loading}
			/>
		</>
	);
};

const UsageWithFilters = () => {
	type CookingTime = { id: number; name: string };
	const cookingTimes: CookingTime[] = [
		{ id: 1, name: "All cooking times" },
		{ id: 2, name: "Max. 30 min" },
		{ id: 3, name: "Max. 60 min" },
	];
	const [cookingTime, setCookingTime] = useState<CookingTime | undefined>(cookingTimes[0]);

	const onChange = (newValue: CookingTime | undefined) => {
		console.log("Filter changed, calling API with new cooking time", newValue);
		setCookingTime(newValue);
	};

	const [filterButton, activeFilters] = useSingleFilter(cookingTime, onChange, {
		label: "Cooking time",
		stringify: (cookingTime) => cookingTime?.name,
		items: cookingTimes.map((cookingTime) => ({ title: cookingTime.name, value: cookingTime })),
		compareFn: (a, b) => a.id === b.id,
		defaultOption: cookingTimes[0],
	});

	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});

	const displayedItems =
		cookingTime?.id === 1
			? items
			: items.filter((x) => (cookingTime?.id === 2 ? x.cookTime <= 30 : x.cookTime <= 60));
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<>
			<TableToolbar filter={filterButton} activeFilters={activeFilters} />
			<Table
				columns={[
					{
						header: {
							property: "title",
							content: "Dish",
							defaultSortDirection: "asc",
						},
						render: (dto) => dto.title,
						options: {
							isKeyColumn: true,
						},
					},
					{
						header: {
							property: "cookTime",
							content: "Cook Time",
						},
						render: (dto) => dto.cookTime,
					},
					{
						header: {
							property: "servings",
							content: "Servings",
						},
						render: (dto) => dto.servings,
					},
				]}
				items={displayedItems}
				sort={sort}
				setSort={(property, direction) => {
					setLoading(true);
					setSort({
						property: property,
						direction: property === sort.property ? invertDirection(sort.direction) : direction,
					});
				}}
				loading={loading}
			/>
		</>
	);
};

const ExpandableUsage = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						property: "cookTime",
						content: "Cook Time",
					},
					render: (dto) => dto.cookTime,
					expandOptions: {
						canCellExpand: (item, pos) => pos.rowNum === 0 || item.cookTime > 30,
						cellExpandRenderer: (item) => (
							<span>Expanded {item.title}. You can place graphs, tables, etc here!</span>
						),
					},
				},
				{
					header: {
						property: "servings",
						content: "Servings",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
		/>
	);
};

const UsageWithHighlight = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						property: "cookTime",
						content: "Cook Time",
					},
					render: (dto) => dto.cookTime,
				},
				{
					header: {
						property: "servings",
						content: "Servings",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			highlightRow={(i) => i.title === "Lasagna"}
		/>
	);
};

const UsageWithDataObserveKeysOnCells = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	const observeKeyPrefix = "observe-key-example";

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
						dataObserveKeys: (item) => {
							return `${observeKeyPrefix}-dish-column-${item.title}`;
						},
					},
				},
				{
					header: {
						content: "Cook Time",
					},
					render: (dto) => dto.cookTime,
					options: {
						dataObserveKeys: (item, pos) => {
							return `${observeKeyPrefix}-cook-column-${pos.columnNum}-row-${pos.rowNum}`;
						},
					},
				},
				{
					header: {
						property: "servings",
						content: "Servings",
					},
					render: (dto) => dto.servings,
					options: {
						dataObserveKeys: (item, pos) => {
							return pos.rowNum === 0
								? `${observeKeyPrefix}-servings-column-row-${pos.rowNum}`
								: undefined;
						},
					},
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
		/>
	);
};

const UsageWithSummary = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	const cookTimeAverage = useMemo<number>(() => {
		const cookTimeSum = items.reduce((sum, item) => sum + item.cookTime, 0);
		return cookTimeSum / items.length;
	}, [items]);

	const servingsTotal = useMemo<number>(
		() => items.reduce((sum, item) => sum + item.servings, 0),
		[items]
	);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						content: "Cook Time",
						tooltip: "in minutes",
					},
					summary: {
						value: cookTimeAverage,
						label: "average",
					},
					render: (dto) => dto.cookTime,
				},
				{
					header: {
						property: "servings",
						content: "Servings",
						tooltip: "in persons",
						notSortable: true,
					},
					summary: {
						value: servingsTotal,
						label: "total",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			caption="Basic usage table"
		/>
	);
};

const UsageWithExporter = () => {
	const lotsOfData: { id: number; title: string; cookTime: number; servings: number }[] = [];
	for (let i = 1; i < 16; i++) {
		lotsOfData.push({
			id: i,
			title: `Recipe ${i}`,
			cookTime: 5 * i,
			servings: Math.floor(i / 25) + 1,
		});
	}

	const [loading, setLoading] = useState(true);
	const [sort, setSort] = useState<SortField<typeof lotsOfData[0]>>({
		property: "title",
		direction: "asc",
	});
	const [items, setItems] = useState(sortItems(lotsOfData, sort));
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const pagedItems = items.slice((page - 1) * pageSize, page * pageSize);

	const getCookTimeData = (item: typeof lotsOfData[0]) => {
		return [
			{ cookMode: "Baked", cookTime: item.cookTime },
			{ cookMode: "Boiled", cookTime: item.cookTime + 5 },
			{ cookMode: "Grilled", cookTime: item.cookTime + 10 },
		];
	};

	const MyExporter = () => (
		<TableExporter
			onExport={(opt) => {
				console.log(
					"Call your export routine (front-end or back-end) with the following options",
					opt
				);
			}}
			buttonContent={
				<>
					<Icon>
						<IconDownload />
					</Icon>
					<InlineText>Export</InlineText>
				</>
			}
			modalTitle="Export"
			pageSize={pageSize}
			total={lotsOfData.length}
			expandableProperties={[{ propertyName: "cookTime", displayName: "Cook time" }]}
		/>
	);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<>
			<TableToolbar exports={<MyExporter />} />
			<Table
				columns={[
					{
						header: {
							property: "title",
							content: "Dish",
							defaultSortDirection: "asc",
						},
						render: (dto) => dto.title,
						options: {
							isKeyColumn: true,
						},
					},
					{
						header: {
							property: "cookTime",
							content: "Cook Time",
						},
						render: (dto) => dto.cookTime,
						expandOptions: {
							canCellExpand: () => true,
							cellExpandRenderer: (item) => (
								<CookTimeSubTable item={item} data={getCookTimeData(item)} />
							),
						},
					},
					{
						header: {
							property: "servings",
							content: "Servings",
						},
						render: (dto) => dto.servings,
					},
				]}
				items={pagedItems}
				sort={sort}
				setSort={(property, direction) => {
					setLoading(true);
					setSort({
						property: property,
						direction: property === sort.property ? invertDirection(sort.direction) : direction,
					});
				}}
				loading={loading}
				pagination={{
					total: items.length,
					count: pagedItems.length,
					page: page,
					setPage: setPage,
					pageSize: pageSize,
					setPageSize: setPageSize,
					cancelLabel: "Cancel",
					confirmLabel: "Confirm",
					firstLabel: "First",
					prevLabel: "Previous",
					nextLabel: "Next",
					lastLabel: "Last",
					pagingInfoLabel: (startIdx: number, endIdx: number, total: number) =>
						`${startIdx} - ${endIdx} of ${total} items`,
					pageLabel: "Page",
					pageXofYLabel: (current: number, total: number) => `Page ${current} of ${total}`,
					pageSizeSelectionLabel: (pageSize: number) => `${pageSize} items`,
					pageSizeSelectorPrefix: "Show",
					pageSizeSelectorPostfix: "per page",
					pageSizeLabel: "Items per page",
					defaultError: "Default pagination error",
					wholeNumberError: "Must be a whole number",
					outOfBoundsError: (total: number) => `Enter a number between 1 and ${total}`,
				}}
			/>
		</>
	);
};

const UsageCsvWithExporter = () => {
	const lotsOfData: { id: number; title: string; cookTime: number; servings: number }[] = [];
	for (let i = 1; i < 16; i++) {
		lotsOfData.push({
			id: i,
			title: `Recipe ${i}`,
			cookTime: 5 * i,
			servings: Math.floor(i / 25) + 1,
		});
	}

	const [loading, setLoading] = useState(true);
	const [sort, setSort] = useState<SortField<typeof lotsOfData[0]>>({
		property: "title",
		direction: "asc",
	});
	const [items, setItems] = useState(sortItems(lotsOfData, sort));
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const pagedItems = items.slice((page - 1) * pageSize, page * pageSize);

	type CookTimeData = { cookMode: string; cookTime: number };
	const getCookTimeData = (item: typeof lotsOfData[0]): CookTimeData[] => {
		return [
			{ cookMode: "Baked", cookTime: item.cookTime },
			{ cookMode: "Boiled", cookTime: item.cookTime + 5 },
			{ cookMode: "Grilled", cookTime: item.cookTime + 10 },
		];
	};

	const columns: ColumnsArray<typeof lotsOfData[0]> = [
		{
			header: {
				property: "title",
				content: "Dish",
				defaultSortDirection: "asc",
			},
			render: (dto) => dto.title,
			options: {
				isKeyColumn: true,
			},
		},
		{
			header: {
				property: "cookTime",
				content: "Cook Time",
			},
			render: (dto) => dto.cookTime,
			expandOptions: {
				canCellExpand: () => true,
				cellExpandRenderer: (item) => <CookTimeSubTable item={item} data={getCookTimeData(item)} />,
			},
			csv: {
				subTable: {
					dataProvider: async (item) => getCookTimeData(item),
					property: "cookTime",
					columns: [
						{
							header: "Cook Mode",
							render: (dto) => dto.cookMode,
						},
						{
							header: "Cook Time",
							render: (dto) => dto.cookTime,
						},
					],
				},
			} as CsvColumnWithSubTableConfig<typeof lotsOfData[0], CookTimeData>,
		},
		{
			header: {
				property: "servings",
				content: "Servings",
			},
			render: (dto) => dto.servings,
			csv: {
				header: "Servings (per person)", // you can override the header for the CSV export
				render: (dto) => dto.servings, // you can override the render function for the CSV export
			},
		},
	];

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<>
			<TableToolbar
				exports={
					<TableCsvExporter
						columns={columns}
						pageNumber={page}
						pageSize={pageSize}
						total={lotsOfData.length}
						expandableProperties={[{ propertyName: "cookTime", displayName: "Cook time" }]}
						fileName="table-example.csv"
						contentPre={`Table Example\n${escapeCsvContent(new Date().toLocaleString())}\n\n`}
						contentPos={"\n\nSiteimprove ©"}
						dataProvider={async ({ pageNumber, pageSize }) =>
							items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
						}
					/>
				}
			/>
			<Table
				columns={columns}
				items={pagedItems}
				sort={sort}
				setSort={(property, direction) => {
					setLoading(true);
					setSort({
						property: property,
						direction: property === sort.property ? invertDirection(sort.direction) : direction,
					});
				}}
				loading={loading}
				pagination={{
					total: items.length,
					count: pagedItems.length,
					page: page,
					setPage: setPage,
					pageSize: pageSize,
					setPageSize: setPageSize,
					cancelLabel: "Cancel",
					confirmLabel: "Confirm",
					firstLabel: "First",
					prevLabel: "Previous",
					nextLabel: "Next",
					lastLabel: "Last",
					pagingInfoLabel: (startIdx: number, endIdx: number, total: number) =>
						`${startIdx} - ${endIdx} of ${total} items`,
					pageLabel: "Page",
					pageXofYLabel: (current: number, total: number) => `Page ${current} of ${total}`,
					pageSizeSelectionLabel: (pageSize: number) => `${pageSize} items`,
					pageSizeSelectorPrefix: "Show",
					pageSizeSelectorPostfix: "per page",
					pageSizeLabel: "Items per page",
					defaultError: "Default pagination error",
					wholeNumberError: "Must be a whole number",
					outOfBoundsError: (total: number) => `Enter a number between 1 and ${total}`,
				}}
			/>
		</>
	);
};

const CookTimeSubTable = (props: {
	item: typeof someData[0];
	data: { cookMode: string; cookTime: number }[];
}) => {
	const { item, data } = props;
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState(data);
	const [sort, setSort] = useState<SortField<typeof data[0]>>({
		property: "cookMode",
		direction: "asc",
	});

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<>
			<h4>Cook Time per Mode - {item.title}</h4>
			<Table
				caption={`Cook Time per Mode - ${item.title}`}
				columns={[
					{
						header: {
							property: "cookMode",
							content: "Cook Mode",
							defaultSortDirection: "asc",
						},
						render: (dto) => dto.cookMode,
						options: {
							isKeyColumn: true,
						},
					},
					{
						header: {
							content: "Cook Time",
							tooltip: "in minutes",
						},
						render: (dto) => dto.cookTime,
					},
				]}
				items={items}
				loading={loading}
				sort={sort}
				setSort={(property, direction) => {
					setSort({
						property: property,
						direction: property === sort.property ? invertDirection(sort.direction) : direction,
					});
				}}
			/>
		</>
	);
};

const UsageWithCombinedColumns = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: [
						{
							property: "id",
							content: "ID",
							defaultSortDirection: "asc",
							"data-observe-key": "table-header-id",
						},
						{
							property: "title",
							content: "Dish",
							defaultSortDirection: "asc",
							"data-observe-key": "table-header-dish",
						},
					],
					render: (dto) => `${dto.id} - ${dto.title}`,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: [
						{
							property: "cookTime",
							content: "Cook Time",
							tooltip: "in minutes",
							"data-observe-key": "table-header-cook-time",
						},
						{
							property: "servings",
							content: "Servings",
							tooltip: "in persons",
							notSortable: true,
							"data-observe-key": "table-header-servings",
						},
					],
					render: (dto) => (
						<BigSmall big={`${dto.cookTime} min`} small={`${dto.servings} persons`} />
					),
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			caption="Basic usage table"
		/>
	);
};

const UsageWithDefaultSorting = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]> | null>(null);
	// Default sort is ascending by ID when no sort is applied
	const defaultSort = { property: "id", direction: "asc" } as SortField<typeof items[0]>;
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort ?? defaultSort));
		setLoading(false);
	}, [sort]);

	return (
		<Table
			columns={[
				{
					header: {
						property: "title",
						content: "Dish",
						defaultSortDirection: "asc",
						"data-observe-key": "table-header-dish",
					},
					render: (dto) => dto.title,
					options: {
						isKeyColumn: true,
					},
				},
				{
					header: {
						property: "cookTime",
						content: "Cook Time",
						tooltip: "in minutes",
						"data-observe-key": "table-header-cook-time",
					},
					render: (dto) => dto.cookTime,
				},
				{
					header: {
						property: "servings",
						content: "Servings",
						tooltip: "in persons",
						"data-observe-key": "table-header-servings",
					},
					render: (dto) => dto.servings,
				},
			]}
			items={items}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				const shouldDefaultSort = sort?.property === property && sort?.direction !== direction;
				setSort(
					shouldDefaultSort
						? null
						: {
								property: property,
								direction:
									property === sort?.property ? invertDirection(sort.direction) : direction,
						  }
				);
			}}
			loading={loading}
			caption="Basic usage table"
		/>
	);
};

const UsageWithLoadingState = () => (
	<Table
		loading={true}
		items={[] as typeof someData}
		caption="Table with loading state enabled"
		columns={[
			{
				header: {
					property: "title",
					content: "Dish",
					defaultSortDirection: "asc",
				},
				render: (dto) => dto.title,
				options: {
					isKeyColumn: true,
				},
			},
			{
				header: {
					content: "Cook Time",
					tooltip: "in minutes",
				},
				render: (dto) => dto.cookTime,
			},
			{
				header: {
					property: "servings",
					content: "Servings",
					tooltip: "in persons",
					notSortable: true,
					"data-observe-key": "table-header-servings",
				},
				render: (dto) => dto.servings,
			},
		]}
		sort={{ property: "title", direction: "asc" }}
		setSort={() => {}}
	/>
);

const UsageWithNoDataState = () => (
	<Table
		loading={false}
		items={[] as typeof someData}
		caption="Table with no data"
		columns={[
			{
				header: {
					property: "title",
					content: "Dish",
					defaultSortDirection: "asc",
				},
				render: (dto) => dto.title,
				options: {
					isKeyColumn: true,
				},
			},
			{
				header: {
					content: "Cook Time",
					tooltip: "in minutes",
				},
				render: (dto) => dto.cookTime,
			},
			{
				header: {
					property: "servings",
					content: "Servings",
					tooltip: "in persons",
					notSortable: true,
					"data-observe-key": "table-header-servings",
				},
				render: (dto) => dto.servings,
			},
		]}
		sort={{ property: "title", direction: "asc" }}
		setSort={() => {}}
	/>
);

function sortItems<TDto>(items: TDto[], sort: SortField<TDto>): TDto[] {
	return items.sort((a, b) => {
		const first = sort.direction === "asc" ? a : b;
		const second = sort.direction === "asc" ? b : a;
		const v1 = first[sort.property];
		const v2 = second[sort.property];
		if (typeof v1 === "string" && typeof v2 === "string") {
			return v1.localeCompare(v2, undefined, {
				numeric: true,
				sensitivity: "base",
			});
		}

		return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
	});
}
