// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---lab-src-components-actions-and-controls-action-bar-action-bar-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/action-bar/action-bar.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-action-bar-action-bar-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-action-menu-action-menu-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/action-menu/action-menu.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-action-menu-action-menu-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-content-switcher-content-switcher-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/content-switcher/content-switcher.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-content-switcher-content-switcher-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-copy-button-copy-button-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/copy-button/copy-button.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-copy-button-copy-button-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-date-picker-date-picker-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/date-picker/date-picker.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-date-picker-date-picker-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-period-picker-period-picker-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/period-picker/period-picker.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-period-picker-period-picker-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-pill-pill-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/pill/pill.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-pill-pill-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-site-picker-site-picker-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/site-picker/site-picker.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-site-picker-site-picker-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-starred-starred-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/starred/starred.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-starred-starred-examples-tsx" */),
  "component---lab-src-components-actions-and-controls-toggle-switch-toggle-switch-examples-tsx": () => import("./../../../lab/src/components/actions-and-controls/toggle-switch/toggle-switch.examples.tsx" /* webpackChunkName: "component---lab-src-components-actions-and-controls-toggle-switch-toggle-switch-examples-tsx" */),
  "component---lab-src-components-context-theme-theme-examples-tsx": () => import("./../../../lab/src/components/context/theme/theme.examples.tsx" /* webpackChunkName: "component---lab-src-components-context-theme-theme-examples-tsx" */),
  "component---lab-src-components-data-visualization-chart-chart-examples-tsx": () => import("./../../../lab/src/components/data-visualization/chart/chart.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-chart-chart-examples-tsx" */),
  "component---lab-src-components-data-visualization-dci-gauge-dci-gauge-examples-tsx": () => import("./../../../lab/src/components/data-visualization/dci-gauge/dci-gauge.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-dci-gauge-dci-gauge-examples-tsx" */),
  "component---lab-src-components-data-visualization-dci-gauge-simple-dci-gauge-simple-examples-tsx": () => import("./../../../lab/src/components/data-visualization/dci-gauge-simple/dci-gauge-simple.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-dci-gauge-simple-dci-gauge-simple-examples-tsx" */),
  "component---lab-src-components-data-visualization-multi-segment-progress-bar-multi-segment-progress-bar-examples-tsx": () => import("./../../../lab/src/components/data-visualization/multi-segment-progress-bar/multi-segment-progress-bar.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-multi-segment-progress-bar-multi-segment-progress-bar-examples-tsx" */),
  "component---lab-src-components-data-visualization-progress-bar-progress-bar-examples-tsx": () => import("./../../../lab/src/components/data-visualization/progress-bar/progress-bar.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-progress-bar-progress-bar-examples-tsx" */),
  "component---lab-src-components-data-visualization-scale-scale-examples-tsx": () => import("./../../../lab/src/components/data-visualization/scale/scale.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-scale-scale-examples-tsx" */),
  "component---lab-src-components-data-visualization-spark-line-spark-line-examples-tsx": () => import("./../../../lab/src/components/data-visualization/spark-line/spark-line.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-spark-line-spark-line-examples-tsx" */),
  "component---lab-src-components-data-visualization-trend-formatter-trend-formatter-examples-tsx": () => import("./../../../lab/src/components/data-visualization/trend-formatter/trend-formatter.examples.tsx" /* webpackChunkName: "component---lab-src-components-data-visualization-trend-formatter-trend-formatter-examples-tsx" */),
  "component---lab-src-components-feedback-learn-info-box-learn-info-box-examples-tsx": () => import("./../../../lab/src/components/feedback/learn-info-box/learn-info-box.examples.tsx" /* webpackChunkName: "component---lab-src-components-feedback-learn-info-box-learn-info-box-examples-tsx" */),
  "component---lab-src-components-feedback-message-message-examples-tsx": () => import("./../../../lab/src/components/feedback/message/message.examples.tsx" /* webpackChunkName: "component---lab-src-components-feedback-message-message-examples-tsx" */),
  "component---lab-src-components-feedback-toast-toast-examples-tsx": () => import("./../../../lab/src/components/feedback/toast/toast.examples.tsx" /* webpackChunkName: "component---lab-src-components-feedback-toast-toast-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-auto-complete-auto-complete-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/auto-complete/auto-complete.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-auto-complete-auto-complete-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-checkbox-checkbox-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/checkbox/checkbox.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-checkbox-checkbox-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-file-input-file-input-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/file-input/file-input.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-file-input-file-input-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-form-element-wrapper-form-element-wrapper-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/form-element-wrapper/form-element-wrapper.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-form-element-wrapper-form-element-wrapper-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-form-form-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/form/form.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-form-form-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-input-field-input-field-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/input-field/input-field.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-input-field-input-field-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-radios-radios-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/radios/radios.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-radios-radios-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-select-select-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/select/select.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-select-select-examples-tsx" */),
  "component---lab-src-components-forms-and-inputs-text-area-text-area-examples-tsx": () => import("./../../../lab/src/components/forms-and-inputs/text-area/text-area.examples.tsx" /* webpackChunkName: "component---lab-src-components-forms-and-inputs-text-area-text-area-examples-tsx" */),
  "component---lab-src-components-navigation-breadcrumbs-breadcrumbs-examples-tsx": () => import("./../../../lab/src/components/navigation/breadcrumbs/breadcrumbs.examples.tsx" /* webpackChunkName: "component---lab-src-components-navigation-breadcrumbs-breadcrumbs-examples-tsx" */),
  "component---lab-src-components-navigation-horizontal-navigation-horizontal-navigation-examples-tsx": () => import("./../../../lab/src/components/navigation/horizontal-navigation/horizontal-navigation.examples.tsx" /* webpackChunkName: "component---lab-src-components-navigation-horizontal-navigation-horizontal-navigation-examples-tsx" */),
  "component---lab-src-components-navigation-pagination-pagination-examples-tsx": () => import("./../../../lab/src/components/navigation/pagination/pagination.examples.tsx" /* webpackChunkName: "component---lab-src-components-navigation-pagination-pagination-examples-tsx" */),
  "component---lab-src-components-navigation-search-navigation-search-navigation-examples-tsx": () => import("./../../../lab/src/components/navigation/search-navigation/search-navigation.examples.tsx" /* webpackChunkName: "component---lab-src-components-navigation-search-navigation-search-navigation-examples-tsx" */),
  "component---lab-src-components-navigation-side-navigation-side-navigation-examples-tsx": () => import("./../../../lab/src/components/navigation/side-navigation/side-navigation.examples.tsx" /* webpackChunkName: "component---lab-src-components-navigation-side-navigation-side-navigation-examples-tsx" */),
  "component---lab-src-components-overlay-modal-modal-examples-tsx": () => import("./../../../lab/src/components/overlay/modal/modal.examples.tsx" /* webpackChunkName: "component---lab-src-components-overlay-modal-modal-examples-tsx" */),
  "component---lab-src-components-overlay-popover-popover-examples-tsx": () => import("./../../../lab/src/components/overlay/popover/popover.examples.tsx" /* webpackChunkName: "component---lab-src-components-overlay-popover-popover-examples-tsx" */),
  "component---lab-src-components-overlay-side-panel-side-panel-examples-tsx": () => import("./../../../lab/src/components/overlay/side-panel/side-panel.examples.tsx" /* webpackChunkName: "component---lab-src-components-overlay-side-panel-side-panel-examples-tsx" */),
  "component---lab-src-components-structure-accordion-accordion-examples-tsx": () => import("./../../../lab/src/components/structure/accordion/accordion.examples.tsx" /* webpackChunkName: "component---lab-src-components-structure-accordion-accordion-examples-tsx" */),
  "component---lab-src-components-structure-base-layout-base-layout-examples-tsx": () => import("./../../../lab/src/components/structure/base-layout/base-layout.examples.tsx" /* webpackChunkName: "component---lab-src-components-structure-base-layout-base-layout-examples-tsx" */),
  "component---lab-src-components-structure-base-layout-new-base-layout-new-examples-tsx": () => import("./../../../lab/src/components/structure/base-layout-new/base-layout-new.examples.tsx" /* webpackChunkName: "component---lab-src-components-structure-base-layout-new-base-layout-new-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-action-list-action-list-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/action-list/action-list.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-action-list-action-list-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-base-table-base-table-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/base-table/base-table.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-base-table-base-table-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-column-header-column-header-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/column-header/column-header.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-column-header-column-header-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-configs-table-config-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/configs/table-config.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-configs-table-config-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-filters-filters-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/filters/filters.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-filters-filters-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-list-table-list-table-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/list-table/list-table.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-list-table-list-table-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-table-table-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/table/table.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-table-table-examples-tsx" */),
  "component---lab-src-components-tables-and-lists-table-toolbar-table-toolbar-examples-tsx": () => import("./../../../lab/src/components/tables-and-lists/table-toolbar/table-toolbar.examples.tsx" /* webpackChunkName: "component---lab-src-components-tables-and-lists-table-toolbar-table-toolbar-examples-tsx" */),
  "component---lab-src-components-text-big-small-big-small-examples-tsx": () => import("./../../../lab/src/components/text/big-small/big-small.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-big-small-big-small-examples-tsx" */),
  "component---lab-src-components-text-formatted-date-formatted-date-examples-tsx": () => import("./../../../lab/src/components/text/formatted-date/formatted-date.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-formatted-date-formatted-date-examples-tsx" */),
  "component---lab-src-components-text-formatted-number-formatted-number-examples-tsx": () => import("./../../../lab/src/components/text/formatted-number/formatted-number.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-formatted-number-formatted-number-examples-tsx" */),
  "component---lab-src-components-text-highlight-highlight-examples-tsx": () => import("./../../../lab/src/components/text/highlight/highlight.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-highlight-highlight-examples-tsx" */),
  "component---lab-src-components-text-markdown-markdown-examples-tsx": () => import("./../../../lab/src/components/text/markdown/markdown.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-markdown-markdown-examples-tsx" */),
  "component---lab-src-components-text-text-highlight-text-highlight-examples-tsx": () => import("./../../../lab/src/components/text/text-highlight/text-highlight.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-text-highlight-text-highlight-examples-tsx" */),
  "component---lab-src-components-text-title-url-title-url-examples-tsx": () => import("./../../../lab/src/components/text/title-url/title-url.examples.tsx" /* webpackChunkName: "component---lab-src-components-text-title-url-title-url-examples-tsx" */),
  "component---lab-src-components-visuals-empty-state-empty-state-examples-tsx": () => import("./../../../lab/src/components/visuals/empty-state/empty-state.examples.tsx" /* webpackChunkName: "component---lab-src-components-visuals-empty-state-empty-state-examples-tsx" */),
  "component---lab-src-components-visuals-illustration-illustration-examples-tsx": () => import("./../../../lab/src/components/visuals/illustration/illustration.examples.tsx" /* webpackChunkName: "component---lab-src-components-visuals-illustration-illustration-examples-tsx" */),
  "component---lab-src-components-visuals-illustration-illustrations-overview-examples-tsx": () => import("./../../../lab/src/components/visuals/illustration/illustrations-overview.examples.tsx" /* webpackChunkName: "component---lab-src-components-visuals-illustration-illustrations-overview-examples-tsx" */),
  "component---lab-src-components-visuals-thumbnail-thumbnail-examples-tsx": () => import("./../../../lab/src/components/visuals/thumbnail/thumbnail.examples.tsx" /* webpackChunkName: "component---lab-src-components-visuals-thumbnail-thumbnail-examples-tsx" */),
  "component---lib-src-components-actions-and-controls-button-button-examples-tsx": () => import("./../../../lib/src/components/actions-and-controls/button/button.examples.tsx" /* webpackChunkName: "component---lib-src-components-actions-and-controls-button-button-examples-tsx" */),
  "component---lib-src-components-feedback-spinner-spinner-examples-tsx": () => import("./../../../lib/src/components/feedback/spinner/spinner.examples.tsx" /* webpackChunkName: "component---lib-src-components-feedback-spinner-spinner-examples-tsx" */),
  "component---lib-src-components-navigation-link-link-examples-tsx": () => import("./../../../lib/src/components/navigation/link/link.examples.tsx" /* webpackChunkName: "component---lib-src-components-navigation-link-link-examples-tsx" */),
  "component---lib-src-components-navigation-tabs-tabs-examples-tsx": () => import("./../../../lib/src/components/navigation/tabs/tabs.examples.tsx" /* webpackChunkName: "component---lib-src-components-navigation-tabs-tabs-examples-tsx" */),
  "component---lib-src-components-overlay-base-popover-base-popover-examples-tsx": () => import("./../../../lib/src/components/overlay/base-popover/base-popover.examples.tsx" /* webpackChunkName: "component---lib-src-components-overlay-base-popover-base-popover-examples-tsx" */),
  "component---lib-src-components-overlay-tooltip-tooltip-examples-tsx": () => import("./../../../lib/src/components/overlay/tooltip/tooltip.examples.tsx" /* webpackChunkName: "component---lib-src-components-overlay-tooltip-tooltip-examples-tsx" */),
  "component---lib-src-components-structure-card-card-examples-tsx": () => import("./../../../lib/src/components/structure/card/card.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-card-card-examples-tsx" */),
  "component---lib-src-components-structure-content-content-examples-tsx": () => import("./../../../lib/src/components/structure/content/content.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-content-content-examples-tsx" */),
  "component---lib-src-components-structure-content-layout-examples-tsx": () => import("./../../../lib/src/components/structure/content/layout.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-content-layout-examples-tsx" */),
  "component---lib-src-components-structure-divider-divider-examples-tsx": () => import("./../../../lib/src/components/structure/divider/divider.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-divider-divider-examples-tsx" */),
  "component---lib-src-components-structure-grid-grid-examples-tsx": () => import("./../../../lib/src/components/structure/grid/grid.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-grid-grid-examples-tsx" */),
  "component---lib-src-components-structure-structure-guide-examples-tsx": () => import("./../../../lib/src/components/structure/structure-guide.examples.tsx" /* webpackChunkName: "component---lib-src-components-structure-structure-guide-examples-tsx" */),
  "component---lib-src-components-text-external-html-external-html-examples-tsx": () => import("./../../../lib/src/components/text/external-html/external-html.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-external-html-external-html-examples-tsx" */),
  "component---lib-src-components-text-heading-heading-examples-tsx": () => import("./../../../lib/src/components/text/heading/heading.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-heading-heading-examples-tsx" */),
  "component---lib-src-components-text-inline-text-inline-text-examples-tsx": () => import("./../../../lib/src/components/text/inline-text/inline-text.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-inline-text-inline-text-examples-tsx" */),
  "component---lib-src-components-text-list-list-examples-tsx": () => import("./../../../lib/src/components/text/list/list.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-list-list-examples-tsx" */),
  "component---lib-src-components-text-paragraph-paragraph-examples-tsx": () => import("./../../../lib/src/components/text/paragraph/paragraph.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-paragraph-paragraph-examples-tsx" */),
  "component---lib-src-components-text-sr-only-sr-only-examples-tsx": () => import("./../../../lib/src/components/text/sr-only/sr-only.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-sr-only-sr-only-examples-tsx" */),
  "component---lib-src-components-text-text-container-text-container-examples-tsx": () => import("./../../../lib/src/components/text/text-container/text-container.examples.tsx" /* webpackChunkName: "component---lib-src-components-text-text-container-text-container-examples-tsx" */),
  "component---lib-src-components-visuals-badge-badge-examples-tsx": () => import("./../../../lib/src/components/visuals/badge/badge.examples.tsx" /* webpackChunkName: "component---lib-src-components-visuals-badge-badge-examples-tsx" */),
  "component---lib-src-components-visuals-icons-icons-examples-tsx": () => import("./../../../lib/src/components/visuals/icons/icons.examples.tsx" /* webpackChunkName: "component---lib-src-components-visuals-icons-icons-examples-tsx" */),
  "component---lib-src-components-visuals-icons-icons-overview-examples-tsx": () => import("./../../../lib/src/components/visuals/icons/icons-overview.examples.tsx" /* webpackChunkName: "component---lib-src-components-visuals-icons-icons-overview-examples-tsx" */),
  "component---lib-src-context-fancy-context-examples-tsx": () => import("./../../../lib/src/context/fancy-context.examples.tsx" /* webpackChunkName: "component---lib-src-context-fancy-context-examples-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-checklist-tsx": () => import("./../../../src/pages/accessibility-checklist.tsx" /* webpackChunkName: "component---src-pages-accessibility-checklist-tsx" */),
  "component---src-pages-adding-components-tsx": () => import("./../../../src/pages/adding-components.tsx" /* webpackChunkName: "component---src-pages-adding-components-tsx" */),
  "component---src-pages-component-guidelines-tsx": () => import("./../../../src/pages/component-guidelines.tsx" /* webpackChunkName: "component---src-pages-component-guidelines-tsx" */),
  "component---src-pages-contributing-tsx": () => import("./../../../src/pages/contributing.tsx" /* webpackChunkName: "component---src-pages-contributing-tsx" */),
  "component---src-pages-design-tokens-tsx": () => import("./../../../src/pages/design-tokens.tsx" /* webpackChunkName: "component---src-pages-design-tokens-tsx" */),
  "component---src-pages-figma-faq-tsx": () => import("./../../../src/pages/figma-FAQ.tsx" /* webpackChunkName: "component---src-pages-figma-faq-tsx" */),
  "component---src-pages-general-faq-tsx": () => import("./../../../src/pages/general-FAQ.tsx" /* webpackChunkName: "component---src-pages-general-faq-tsx" */),
  "component---src-pages-getting-started-in-a-new-project-tsx": () => import("./../../../src/pages/getting-started-in-a-new-project.tsx" /* webpackChunkName: "component---src-pages-getting-started-in-a-new-project-tsx" */),
  "component---src-pages-grammar-and-mechanics-tsx": () => import("./../../../src/pages/grammar-and-mechanics.tsx" /* webpackChunkName: "component---src-pages-grammar-and-mechanics-tsx" */),
  "component---src-pages-icons-faq-tsx": () => import("./../../../src/pages/icons-FAQ.tsx" /* webpackChunkName: "component---src-pages-icons-faq-tsx" */),
  "component---src-pages-illustrations-faq-tsx": () => import("./../../../src/pages/illustrations-FAQ.tsx" /* webpackChunkName: "component---src-pages-illustrations-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-changelog-tsx": () => import("./../../../src/pages/lab-changelog.tsx" /* webpackChunkName: "component---src-pages-lab-changelog-tsx" */),
  "component---src-pages-lib-changelog-tsx": () => import("./../../../src/pages/lib-changelog.tsx" /* webpackChunkName: "component---src-pages-lib-changelog-tsx" */),
  "component---src-pages-tone-of-voice-tsx": () => import("./../../../src/pages/tone-of-voice.tsx" /* webpackChunkName: "component---src-pages-tone-of-voice-tsx" */),
  "component---src-pages-versioning-strategy-tsx": () => import("./../../../src/pages/versioning-strategy.tsx" /* webpackChunkName: "component---src-pages-versioning-strategy-tsx" */),
  "component---src-pages-word-list-tsx": () => import("./../../../src/pages/word-list.tsx" /* webpackChunkName: "component---src-pages-word-list-tsx" */)
}

