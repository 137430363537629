import React, { useEffect, useRef, useState } from "react";
import Highcharts, { AnnotationDraggableValue, VerticalAlignValue } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
	SrOnly,
	Link,
	useFormattingLanguage,
	Button,
	DataObserveKey,
	VisualComponent,
	useUniqueId,
	cn,
} from "@siteimprove/fancylib";
import { mergeObjects } from "../../../utils/object-utils";
import { toAccessibilityDateString } from "../../../utils/locale";
import { useLabTranslations } from "../../../translations/translations";
import { useDesignToken } from "../../context/theme/theme";
import { ChartContextMenu } from "./chart-context-menu";
import { DataTableModal } from "./data-table-modal";
import {
	AnnotationItem,
	Annotations,
	DateOptions,
	AnnotationVisibility,
	AnnotationAreasVisibleIn,
} from "./annotations/annotations";
import { AnnotationIconTooltip, AnnotationIconProps } from "./annotations/annotation-icon-tooltip";
import { createAnnotationsLabels, updateIconsProps } from "./annotations/annotations-utils";
import {
	setGlobalOptions,
	prepareChartOptions,
	prepTickInterval,
	highchartsCustomFunctions,
	TooltipHeaderFormatter,
	TooltipPointFormatter,
	ChartSize,
} from "./chart-utils";
import { ChartLegend } from "./chart-legend";
import * as scss from "./chart.scss";

highchartsCustomFunctions(Highcharts);

export type {
	AnnotationItem,
	DateOptions,
	AnnotationAreasVisibleIn,
	AnnotationIconProps,
	TooltipHeaderFormatter,
	TooltipPointFormatter,
};
export { AnnotationVisibility };

export type ChartProps = {
	/** Options for Highcharts chart */
	options: Highcharts.Options;
	/** Chart height (default medium, i.e. 310px) */
	height?: keyof typeof ChartSize | Highcharts.ChartOptions["height"];
	/** CSS class for the Highcharts wrapper */
	containerClassName?: string;
	/** Use native Highcharts legend */
	useHighchartsLegend?: boolean;
	/** Label for screen reader region */
	screenReaderRegionLabel?: string;
	/** Label for chart */
	screenReaderChartHeading?: string;
	/** Default chart label */
	defaultChartTitle?: string;
	/** Summary for the data displayed in the data table */
	tableSummary?: string;
	/** Label for chart container */
	chartContainerLabel?: string;
	/** Legend item label */
	legendItem?: string;
	/** Callback for toggling series visibility */
	onToggleSeries?: (seriesIndex: number, visible: boolean) => void;
	/** Description for chart type map type */
	chartTypesMapTypeDescription?: string;
	/** Description for chart type combination chart */
	chartTypesCombinationChart?: string;
	/** Description for chart type default single */
	chartTypesDefaultSingle?: string;
	/** Description for chart type default multiple */
	chartTypesDefaultMultiple?: string;
	/** Description for chart type spline single */
	chartTypesSplineSingle?: string;
	/** Description for chart type spline multiple */
	chartTypesSplineMultiple?: string;
	/** Description for chart type line single */
	chartTypesLineSingle?: string;
	/** Description for chart type line multiple */
	chartTypesLineMultiple?: string;
	/** Description for chart type column single */
	chartTypesColumnSingle?: string;
	/** Description for chart type column multiple */
	chartTypesColumnMultiple?: string;
	/** Description for chart type bar single */
	chartTypesBarSingle?: string;
	/** Description for chart type bar multiple */
	chartTypesBarMultiple?: string;
	/** Description for chart type pie single */
	chartTypesPieSingle?: string;
	/** Description for chart type pie multiple */
	chartTypesPieMultiple?: string;
	/** Description for x-axis singluar */
	xAxisDescriptionSingular?: string;
	/** Description for x-axis plural */
	xAxisDescriptionPlural?: string;
	/**	Table column header for x-axis when the type is "category", "linear", or "logarithmic" */
	categoryColumnHeader?: string;
	/**	Table column header for x-axis when the type is "datetime" */
	datetimeColumnHeader?: string;
	/** Description for y-axis singluar */
	yAxisDescriptionSingular?: string;
	/** Description for y-axis plural */
	yAxisDescriptionPlural?: string;
	/** Chart series summary default */
	chartSeriesDefault?: string;
	/** Chart series summary default combination */
	chartSeriesDefaultCombination?: string;
	/** Chart series summary line */
	chartSeriesLine?: string;
	/** Chart series summary line combination */
	chartSeriesLineCombination?: string;
	/** Chart series summary spline */
	chartSeriesSpline?: string;
	/** Chart series summary spline combination */
	chartSeriesSplineCombination?: string;
	/** Chart series summary column */
	chartSeriesColumn?: string;
	/** Chart series summary column combination */
	chartSeriesColumnCombination?: string;
	/** Chart series summary bar */
	chartSeriesBar?: string;
	/** Chart series summary bar combination */
	chartSeriesBarCombination?: string;
	/** Chart series summary pie */
	chartSeriesPie?: string;
	/** Chart series summary pie combination */
	chartSeriesScatterCombination?: string;
	/** Chart series summary map combination */
	chartSeriesMapCombination?: string;
	/** Chart series summary mapbubble combination */
	chartSeriesMapbubbleCombination?: string;
	/** Description of x-axis */
	xAxisDescription?: string;
	/** Description of y-axis */
	yAxisDescription?: string;
	/** Label for the context menu. It will be appended with the chart title. */
	contextMenuForLabel?: string;
	/** Label for the enhanced contrast button in the context menu */
	contextMenuEnhancedContrastLabel?: string;
	/** Tooltip content for the enhanced contrast button */
	contextMenuEnhancedContrastTooltip?: string;
	/** Label for table view button in the context menu */
	contextMenuTableViewLabel?: string;
	/** Tooltip content for the table view button */
	contextMenuTableViewTooltip?: string;
	/** Hides the context menu icon */
	hideContextMenu?: boolean;
	/** Hides the enhanced contrast context option */
	hideEnhancedContrastOption?: boolean;
	/** Should overflow be allowed (if not, tooltip might be cut off) */
	overflowable?: boolean;
	/** Enables enhanced contrast mode */
	a11yMode?: boolean;
	/** Tooltip header formatters */
	tooltipHeaderFormatter?: TooltipHeaderFormatter;
	/** Tooltip point formatters */
	tooltipPointFormatter?: TooltipPointFormatter;
} & DataObserveKey &
	VisualComponent;

export type ChartWithoutAnnotationProps = {
	/** Should show annotation button */
	showAnnotationButton?: false;
	/** Annotations array */
	annotations?: never;
	/** Chart Period (for Annotations) */
	chartPeriod?: never;
	/** PUT call to update Annotation */
	onUpdateAnnotation?: never;
	/** CREATE new Annotation */
	onCreateAnnotation?: never;
	/** DELETE new Annotation */
	onDeleteAnnotation?: never;
	/** Date Options for the chart annotations */
	chartDateOptions?: never;
	/** Array of areas to allow annotations to be visible in */
	annotationAreasVisibleIn?: never;
} & ChartProps;

export type ChartWithAnnotationProps = {
	/** Should show annotation button */
	showAnnotationButton: true;
	/** Annotations array */
	annotations: AnnotationItem[];
	/** Chart Period (for Annotations) */
	chartPeriod: Date[];
	/** PUT call to update Annotation */
	onUpdateAnnotation: (data: Omit<Partial<AnnotationItem>, "createdBy">) => Promise<void>;
	/** CREATE new Annotation */
	onCreateAnnotation?: (data: Omit<AnnotationItem, "id" | "createdBy">) => Promise<void>;
	/** DELETE new Annotation */
	onDeleteAnnotation: (id: string | number) => Promise<void>;
	/** Date Options for the chart annotations */
	chartDateOptions: DateOptions;
	/** Array of areas to allow annotations to be visible in */
	annotationAreasVisibleIn: AnnotationAreasVisibleIn[];
} & ChartProps;

export function Chart(props: ChartWithoutAnnotationProps | ChartWithAnnotationProps): JSX.Element {
	const {
		defaultChartTitle,
		contextMenuForLabel,
		contextMenuEnhancedContrastLabel,
		contextMenuEnhancedContrastTooltip,
		contextMenuTableViewLabel,
		contextMenuTableViewTooltip,
		legendItem,
		hideContextMenu,
		hideEnhancedContrastOption,
		overflowable,
		a11yMode,
		className,
		containerClassName,
		style,
		useHighchartsLegend,
		onToggleSeries,
	} = props;

	// Create ref object for the chart
	const chartComponentRef = useRef<HighchartsReact>(null);

	const i18n = useLabTranslations();

	// Set global options
	setGlobalOptions(Highcharts, useFormattingLanguage());

	// Get the locale
	const locale = useFormattingLanguage();

	const [a11yModeEnabled, setA11yModeEnabled] = useState<boolean>(!!a11yMode);

	// Manage state for chartOptions
	const [opts, setChartOptions] = useChartOptions(
		{
			...props,
			a11yMode: a11yModeEnabled,
		},
		chartComponentRef,
		[a11yModeEnabled]
	);

	// Legends visibility
	const legendVisible = opts.plotOptions?.series?.showInLegend !== false;

	// Code to show a modal with a table view of the chart data
	const [showDataTable, setShowDataTable] = useState(false);

	// Enable/disable the enhanced contrast mode
	const toggleA11yMode = (val: boolean) => {
		setA11yModeEnabled(val);
		setChartOptions(
			mergeObjects(opts, {
				plotOptions: {
					series: {
						marker: {
							enabled: val,
						},
					},
				},
			})
		);
	};

	// annotations
	const [annotationsButton, annotationsIcons, annotationsTable] = useAnnotations(
		props,
		[opts, setChartOptions],
		chartComponentRef
	);

	const skipChartAnchorId = useUniqueId("skipchart");

	// wrap Highcharts getDataRows method in a custom function
	// as this method throws an error when called on a chart with no data [1]
	// or when there's a single series with no data [2]
	const getDataRows = () => {
		const chartInstance = chartComponentRef.current?.chart;
		if (
			chartInstance &&
			/* [1] */ chartInstance.series?.length &&
			/* [2] */ chartInstance.series.some(
				(series) => series.visible && "data" in series && series.data?.length
			)
		) {
			return chartInstance.getDataRows();
		}
		return [[]];
	};

	// The elements to render in the DOM including an SrOnly component, the actual chart and the Modal component
	return (
		<div
			data-component="chart"
			data-observe-key={props["data-observe-key"]}
			className={cn("fancy-Chart", overflowable && scss.overflowable, className)}
			style={style}
		>
			<SrOnly>
				<Link href={`#${skipChartAnchorId}`}>{i18n.skipChart}</Link>
			</SrOnly>
			{!useHighchartsLegend && chartComponentRef.current?.chart && (
				<div className={scss.toolbar}>
					<div className={scss.left}>
						{legendVisible && (
							<ChartLegend
								chart={chartComponentRef.current?.chart}
								ariaLabelTemplate={legendItem ?? i18n.chartLegendItem}
								onToggle={onToggleSeries}
							/>
						)}
					</div>
					<div className={scss.right}>
						{annotationsButton}
						{!hideContextMenu && (
							<ChartContextMenu
								contextMenuLabel={`${contextMenuForLabel ?? i18n.chartContextMenuForLabel} ${
									defaultChartTitle ?? i18n.defaultChartTitle
								}`}
								enhancedContrastState={[a11yModeEnabled, toggleA11yMode]}
								enhancedContrastLabel={
									contextMenuEnhancedContrastLabel ?? i18n.chartContextMenuEnhancedContrastLabel
								}
								enhancedContrastTooltip={
									contextMenuEnhancedContrastTooltip ?? i18n.chartContextMenuEnhancedContrastTooltip
								}
								tableViewState={[showDataTable, setShowDataTable]}
								tableViewLabel={contextMenuTableViewLabel ?? i18n.chartContextMenuTableViewLabel}
								tableViewTooltip={
									contextMenuTableViewTooltip ?? i18n.chartContextMenuTableViewTooltip
								}
								hideEnhancedContrastOption={hideEnhancedContrastOption}
							/>
						)}
					</div>
				</div>
			)}
			<div className={scss.chartContainer}>
				{useHighchartsLegend && (
					<>
						{annotationsButton}
						{!hideContextMenu && (
							<div
								className={
									opts.plotOptions?.series?.showInLegend === false
										? scss.contextMenuWithoutLegend
										: scss.contextMenu
								}
							>
								<ChartContextMenu
									contextMenuLabel={`${contextMenuForLabel ?? i18n.chartContextMenuForLabel} ${
										defaultChartTitle ?? i18n.defaultChartTitle
									}`}
									enhancedContrastState={[a11yModeEnabled, toggleA11yMode]}
									enhancedContrastLabel={
										contextMenuEnhancedContrastLabel ?? i18n.chartContextMenuEnhancedContrastLabel
									}
									enhancedContrastTooltip={
										contextMenuEnhancedContrastTooltip ??
										i18n.chartContextMenuEnhancedContrastTooltip
									}
									tableViewState={[showDataTable, setShowDataTable]}
									tableViewLabel={contextMenuTableViewLabel ?? i18n.chartContextMenuTableViewLabel}
									tableViewTooltip={
										contextMenuTableViewTooltip ?? i18n.chartContextMenuTableViewTooltip
									}
									hideEnhancedContrastOption={hideEnhancedContrastOption}
								/>
							</div>
						)}
					</>
				)}
				<HighchartsReact
					containerProps={{
						className: cn("fancy-ChartContainer", containerClassName),
					}}
					highcharts={Highcharts}
					options={opts}
					ref={chartComponentRef}
				/>
				{annotationsIcons}
				{annotationsTable}
			</div>
			{!hideContextMenu && chartComponentRef.current && (
				<DataTableModal
					dataRows={getDataRows()}
					axisType={
						Array.isArray(chartComponentRef.current.chart.options.xAxis)
							? chartComponentRef.current.chart.options.xAxis[0].type
							: chartComponentRef.current.chart.options.xAxis?.type
					}
					chartTitle={defaultChartTitle ?? i18n.defaultChartTitle}
					locale={locale}
					onClose={() => setShowDataTable(false)}
					show={showDataTable}
				/>
			)}
			<div id={skipChartAnchorId} />
		</div>
	);
}

function useChartOptions(
	props: ChartProps,
	chartRef: React.RefObject<HighchartsReact>,
	dependencies: React.DependencyList = []
): [Highcharts.Options, React.Dispatch<React.SetStateAction<Highcharts.Options>>] {
	// Get the locale
	const locale = useFormattingLanguage();
	const designTokens = useDesignToken();
	const i18n = useLabTranslations();

	const buildOptions = () => {
		// Prepare chart options
		const opts = prepareChartOptions(
			props.options,
			props.height || "medium",
			props.useHighchartsLegend,
			props.screenReaderRegionLabel ?? i18n.chartScreenReaderRegionLabel,
			props.screenReaderChartHeading ?? i18n.screenReaderChartHeading,
			props.defaultChartTitle ?? i18n.defaultChartTitle,
			props.tableSummary ?? i18n.chartTableSummary,
			props.chartContainerLabel ?? i18n.chartContainerLabel,
			props.legendItem ?? i18n.chartLegendItem,
			props.chartTypesMapTypeDescription ?? i18n.chartTypesMapTypeDescription,
			props.chartTypesCombinationChart ?? i18n.chartTypesCombinationChart,
			props.chartTypesDefaultSingle ?? i18n.chartTypesDefaultSingle,
			props.chartTypesDefaultMultiple ?? i18n.chartTypesDefaultMultiple,
			props.chartTypesSplineSingle ?? i18n.chartTypesSplineSingle,
			props.chartTypesSplineMultiple ?? i18n.chartTypesSplineMultiple,
			props.chartTypesLineSingle ?? i18n.chartTypesLineSingle,
			props.chartTypesLineMultiple ?? i18n.chartTypesLineMultiple,
			props.chartTypesColumnSingle ?? i18n.chartTypesColumnSingle,
			props.chartTypesColumnMultiple ?? i18n.chartTypesColumnMultiple,
			props.chartTypesBarSingle ?? i18n.chartTypesBarSingle,
			props.chartTypesBarMultiple ?? i18n.chartTypesBarMultiple,
			props.chartTypesPieSingle ?? i18n.chartTypesPieSingle,
			props.chartTypesPieMultiple ?? i18n.chartTypesPieMultiple,
			props.xAxisDescriptionSingular ?? i18n.chartXAxisDescriptionSingular,
			props.xAxisDescriptionPlural ?? i18n.chartXAxisDescriptionPlural,
			props.categoryColumnHeader ?? i18n.chartCategoryColumnHeader,
			props.datetimeColumnHeader ?? i18n.chartDatetimeColumnHeader,
			props.yAxisDescriptionSingular ?? i18n.chartYAxisDescriptionSingular,
			props.yAxisDescriptionPlural ?? i18n.chartYAxisDescriptionPlural,
			props.chartSeriesDefault ?? i18n.chartSeriesDefault,
			props.chartSeriesDefaultCombination ?? i18n.chartSeriesDefaultCombination,
			props.chartSeriesLine ?? i18n.chartSeriesLine,
			props.chartSeriesLineCombination ?? i18n.chartSeriesLineCombination,
			props.chartSeriesSpline ?? i18n.chartSeriesSpline,
			props.chartSeriesSplineCombination ?? i18n.chartSeriesSplineCombination,
			props.chartSeriesColumn ?? i18n.chartSeriesColumn,
			props.chartSeriesColumnCombination ?? i18n.chartSeriesColumnCombination,
			props.chartSeriesBar ?? i18n.chartSeriesBar,
			props.chartSeriesBarCombination ?? i18n.chartSeriesBarCombination,
			props.chartSeriesPie ?? i18n.chartSeriesPie,
			props.chartSeriesScatterCombination ?? i18n.chartSeriesScatterCombination,
			props.chartSeriesMapCombination ?? i18n.chartSeriesMapCombination,
			props.chartSeriesMapbubbleCombination ?? i18n.chartSeriesMapbubbleCombination,
			props.xAxisDescription ?? i18n.chartXAxisDescription,
			props.yAxisDescription ?? i18n.chartYAxisDescription,
			!!props.a11yMode,
			locale,
			props.tooltipHeaderFormatter,
			props.tooltipPointFormatter,
			designTokens
		);

		// Patch up xAxis
		prepTickInterval(opts, "xAxis");

		// Patch up yAxis
		prepTickInterval(opts, "yAxis");

		// Ensure accessible
		ensureAccessibleAxis(opts, locale, chartRef);

		return opts;
	};

	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(buildOptions());

	useEffect(() => {
		setChartOptions(buildOptions());
	}, [props.options, props.height].concat(dependencies));

	return [chartOptions, setChartOptions];
}

function ensureAccessibleAxis(
	opts: Highcharts.Options,
	locale: string,
	chartRef: React.RefObject<HighchartsReact>
) {
	const xAxisOptionsAsArray: Highcharts.XAxisOptions[] =
		opts.xAxis == undefined ? [] : Array.isArray(opts.xAxis) ? opts.xAxis : [opts.xAxis];
	const updatedXAxisOptions = xAxisOptionsAsArray.map((xAxisOptions, index) => {
		if (xAxisOptions?.type === "datetime") {
			return {
				...xAxisOptions,
				accessibility: {
					// Set description for screen readers using the minimun and maximum values of the xAxis
					description: `Time from ${toAccessibilityDateString(
						new Date(chartRef.current?.chart.xAxis[index].getExtremes().dataMin || 0),
						locale
					)} to ${toAccessibilityDateString(
						new Date(chartRef.current?.chart.xAxis[index].getExtremes().dataMax || 0),
						locale
					)}`,
				},
			};
		}
		return xAxisOptions;
	});

	const yAxisOptionsAsArray: Highcharts.YAxisOptions[] =
		opts.yAxis == undefined ? [] : Array.isArray(opts.yAxis) ? opts.yAxis : [opts.yAxis];
	const updatedYAxisOptions = yAxisOptionsAsArray.map((yAxisOptions, index) => {
		if (yAxisOptions?.type === "datetime") {
			return {
				...yAxisOptions,
				accessibility: {
					// Set description for screen readers using the minimun and maximum values of the yAxis
					description: `Time from ${toAccessibilityDateString(
						new Date(chartRef.current?.chart.yAxis[index].getExtremes().dataMin || 0),
						locale
					)} to ${toAccessibilityDateString(
						new Date(chartRef.current?.chart.yAxis[index].getExtremes().dataMax || 0),
						locale
					)}`,
				},
			};
		}
		return yAxisOptions;
	});

	Object.assign(opts, {
		xAxis: updatedXAxisOptions,
		yAxis: updatedYAxisOptions,
	});
}

function useAnnotations(
	props: ChartWithoutAnnotationProps | ChartWithAnnotationProps,
	optsState: [Highcharts.Options, React.Dispatch<React.SetStateAction<Highcharts.Options>>],
	chartRef: React.RefObject<HighchartsReact>
): [
	annotationButton: JSX.Element | null,
	annotationsIcons: JSX.Element[],
	annotationsTable: JSX.Element | null
] {
	const { showAnnotationButton, annotations, chartPeriod } = props;

	const [opts, setChartOptions] = optsState;

	const i18n = useLabTranslations();

	// Annotations control
	const [isXAxisDatetimeWithTickInterval, setIsXAxisDatetimeWithTickInterval] = useState(false);
	const [showAnnotations, setShowAnnotations] = useState(false);

	// Annotations Highlighted Id
	const [annotationsHighlightedIds, setAnnotationsHighlightedIds] = useState<(string | number)[]>(
		[]
	);

	const initialIconsProps = updateIconsProps(annotations, [], i18n);
	const [iconsProps, setIconProps] = useState<AnnotationIconProps[]>(initialIconsProps);

	useEffect(() => {
		if (!annotations || !chartPeriod) return;

		const annotationsWithinChartPeriod: AnnotationItem[] = annotations.filter(
			(item) => item.date >= chartPeriod[0] && item.date <= chartPeriod[1]
		);

		// Remove any duplicate iconProp
		if (iconsProps.length > annotationsWithinChartPeriod.length) {
			const iconsPropsWithoutDuplicates: AnnotationIconProps[] = [];
			const iconsIds: (string | number)[] = [];
			for (let i = 0; i < iconsProps.length; i++) {
				const ids = iconsProps[i].ids;
				if (!iconsIds.includes(ids[0])) {
					iconsIds.push(...ids);
					iconsPropsWithoutDuplicates.push(iconsProps[i]);
				}
			}
			setIconProps(iconsPropsWithoutDuplicates);
		}
	}, [iconsProps]);

	useEffect(() => {
		if (!annotations || !chartPeriod) return;

		const minY = chartRef.current?.chart.yAxis[0].min ?? 0;
		const annotationsLabels = createAnnotationsLabels(annotations, chartPeriod, minY);
		const annotationsOptions: Highcharts.AnnotationsOptions[] = [
			{
				crop: false,
				draggable: "" as AnnotationDraggableValue,
				labelOptions: {
					backgroundColor: "none",
					verticalAlign: "top" as VerticalAlignValue,
					y: 0,
					borderWidth: 0,
					useHTML: true,
					crop: false,
				},
				labels: annotationsLabels,
			},
		];

		// remove icons of all previous annotations
		setIconProps([]);
		// update annotations list
		setChartOptions({ ...opts, annotations: annotationsOptions });

		const chart = chartRef.current?.chart;
		if (chart) chart.redraw();
	}, [annotations]);

	useEffect(() => {
		const updatedIconsProps = updateIconsProps(annotations, iconsProps, i18n);
		setIconProps(updatedIconsProps);
	}, [opts.annotations]);

	// Function to add start and end dates to screen reader description when using the "datetime" type of axis
	useEffect(() => {
		const xAxisOptionsAsArray: Highcharts.XAxisOptions[] =
			opts.xAxis == undefined ? [] : Array.isArray(opts.xAxis) ? opts.xAxis : [opts.xAxis];

		xAxisOptionsAsArray.forEach((xAxisOptions) => {
			if (xAxisOptions?.type === "datetime" && xAxisOptions?.tickInterval !== undefined) {
				// Control variable, if type !== 'datetime', annotations will not display
				setIsXAxisDatetimeWithTickInterval(true);
			}
		});
	}, [opts.xAxis]);

	const annotationsButton = showAnnotationButton ? (
		<Button
			className={cn(props.useHighchartsLegend && scss.showAnnotations)}
			onClick={() => {
				if (isXAxisDatetimeWithTickInterval) {
					setShowAnnotations(!showAnnotations);
				} else {
					console.error(
						"Annotations table can't be displayed because xAxis type is not set to 'datetime' or xAxis tickInterval property is not defined."
					);
				}
			}}
			aria-expanded={showAnnotations}
		>
			{i18n.showAnnotations}
			<SrOnly>{i18n.showAnnotationsSrOnly}</SrOnly>
		</Button>
	) : null;

	const annotationsIcons = iconsProps.map((iconProp, index) => (
		<AnnotationIconTooltip
			key={index}
			coordinates={{ left: iconProp.coordinates.left, top: iconProp.coordinates.top }}
			content={{ header: iconProp.content.header, text: iconProp.content.text }}
			typeOfAnnotation={iconProp.typeOfAnnotation}
			ids={iconProp.ids}
			setAnnotationsHighlightedIds={setAnnotationsHighlightedIds}
			setShowAnnotations={setShowAnnotations}
		/>
	));

	const annotationsTable = showAnnotationButton ? (
		<Annotations
			showAnnotations={showAnnotations}
			hideAnnotations={setShowAnnotations}
			annotationsData={props.annotations}
			chartPeriod={props.chartPeriod}
			dateOptions={props.chartDateOptions}
			onUpdateAnnotation={props.onUpdateAnnotation}
			onCreateAnnotation={props.onCreateAnnotation}
			onDeleteAnnotation={props.onDeleteAnnotation}
			highlightedIds={annotationsHighlightedIds}
			annotationAreasVisibleIn={props.annotationAreasVisibleIn}
		/>
	) : null;

	return [annotationsButton, annotationsIcons, annotationsTable];
}
