import React from "react";
import scss from "./page-header-utilities.scss";

export interface PageHeaderUtilitiesProps {
	/** Global utilities. */
	global?: React.ReactNode;
	/** Product specific utilities. */
	productSpecific?: React.ReactNode;
}

export function PageHeaderUtilities(props: PageHeaderUtilitiesProps): JSX.Element {
	const { productSpecific, global } = props;

	if (!productSpecific && !global) {
		return <></>;
	}

	return (
		<div className={scss.utilities}>
			{global}
			{productSpecific}
		</div>
	);
}
