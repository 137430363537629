import React, { ReactNode, ReactElement, useState, useRef, useLayoutEffect } from "react";
import {
	H2,
	Icon,
	IconLearn,
	IconClose,
	Button,
	Card,
	Content,
	VisualComponent,
	DataObserveKey,
	TabsProps,
	useTranslations,
	useUniqueId,
	cn,
	ButtonSize,
} from "@siteimprove/fancylib";
import { IllustrationEducate } from "@siteimprove/fancyvisuals";
import { isMouseEventKeyboard } from "../../../utils/shorthands";
import { Trigger } from "../../../utils/dom-utils";
import { useDidUpdate } from "../../../utils/hooks";
import { Illustration } from "../../visuals/illustration/illustration";
import * as scss from "./learn-info-box.scss";

type LearnInfoBoxProps = {
	/** Heading of the learn info box */
	heading: string;
	/** Content of the learn info box */
	content: ReactNode;
	/** Tabs for the tabset within the learn info box */
	tabs?: ReactElement<TabsProps>;
	/** Renders the learn info box in expanded state to start with */
	openExpanded?: boolean;
	/** Callback that is called when the learn info box changes between expanded and collapsed state */
	onToggle?: (expanded: boolean) => void;
	/** Button size of the learn info box toggle button */
	buttonSize?: ButtonSize;
} & DataObserveKey &
	VisualComponent;

export function useLearnInfoBox(props: LearnInfoBoxProps): [ReactNode, ReactNode | undefined] {
	const {
		content,
		openExpanded,
		onToggle,
		className,
		style,
		heading,
		tabs,
		buttonSize = "large",
	} = props;
	const [expanded, setExpanded] = useState(openExpanded || false);
	const contentId = useUniqueId("learn-info-box");
	const buttonRef = React.createRef<HTMLButtonElement>();
	const contentRef = React.createRef<HTMLDivElement>();
	const trigger = useRef(Trigger.Mouse);
	const i18n = useTranslations();

	useDidUpdate(() => {
		!expanded && buttonRef.current?.focus();
		onToggle?.(expanded);
	}, [expanded]);

	useLayoutEffect(() => {
		expanded && setExpanded(true);
	}, []);

	const infoBoxRef = useRef<HTMLDivElement>(null);

	// Code for expanding and collapsing a section is inspired by Brandon Smith
	// Article and code: https://css-tricks.com/using-css-transitions-auto-dimensions/)
	const expandSection = () => {
		if (!infoBoxRef.current) return;
		infoBoxRef.current.classList.remove(scss.hide);
		infoBoxRef.current.classList.add(scss.show);
		const sectionHeight: number = infoBoxRef.current.scrollHeight;
		infoBoxRef.current.style.height = sectionHeight + "px";
		// When the expand transition has finished:
		// Set "display: block" (again) to make sure the element is visible
		// Set "height: auto" to make the height flexible on window resize
		infoBoxRef.current.addEventListener(
			"transitionend",
			() => {
				if (!infoBoxRef.current) return;
				infoBoxRef.current.classList.remove(scss.hide);
				infoBoxRef.current.classList.add(scss.show);
				infoBoxRef.current.style.height = "auto";
			},
			{ once: true }
		);
	};

	const collapseSection = () => {
		if (!infoBoxRef.current) return;
		const sectionHeight: number = infoBoxRef.current.scrollHeight;
		const elementTransition: string = infoBoxRef.current.style.transition;
		infoBoxRef.current.style.transition = "";
		requestAnimationFrame(function () {
			if (!infoBoxRef.current) return;
			infoBoxRef.current.style.height = sectionHeight + "px";
			infoBoxRef.current.style.transition = elementTransition;
			requestAnimationFrame(function () {
				if (!infoBoxRef.current) return;
				infoBoxRef.current.style.height = "0px";
				// When the collapse transition has finished:
				// Set "display: none" to hide the element
				// Set "height: 0px" (again) to make sure the element has
				// the right starting point for the next expand transition
				infoBoxRef.current.addEventListener(
					"transitionend",
					() => {
						if (!infoBoxRef.current) return;
						infoBoxRef.current.classList.remove(scss.show);
						infoBoxRef.current.classList.add(scss.hide);
						infoBoxRef.current.style.height = "0px";
					},
					{ once: true }
				);
			});
		});
	};

	const button = (
		<Button
			data-component="learn-info-box"
			size={buttonSize}
			onClick={(e) => {
				trigger.current = isMouseEventKeyboard(e) ? Trigger.Keyboard : Trigger.Mouse;
				if (expanded) {
					setExpanded(false);
					collapseSection();
				} else {
					setExpanded(true);
					expandSection();
				}
			}}
			aria-label={i18n.learnMoreAboutFeature}
			className={cn(scss.learnButton, expanded && scss.expanded)}
			aria-expanded={expanded}
			aria-controls={contentId}
			ref={buttonRef}
			data-observe-key={props["data-observe-key"]}
		>
			<Icon>
				<IconLearn />
			</Icon>
			{i18n.learn}
		</Button>
	);

	const headingId = useUniqueId("learn-heading");
	const headerNode = (
		<div className={cn(scss.header, tabs && scss.tabbedHeader)}>
			<Icon>
				<IconLearn className={scss.learnIcon} />
			</Icon>
			{tabs ? tabs : <H2 id={headingId}>{heading}</H2>}
			<Button
				variant="borderless"
				className={scss.close}
				onClick={(e) => {
					trigger.current = isMouseEventKeyboard(e) ? Trigger.Keyboard : Trigger.Mouse;
					setExpanded(false);
					collapseSection();
				}}
				aria-label={i18n.closeSection}
			>
				<Icon>
					<IconClose />
				</Icon>
			</Button>
		</div>
	);

	const infoBox = (
		<div
			data-observe-key={props["data-observe-key"]}
			className={cn(scss.learnWrapper, openExpanded && scss.openExpanded, className)}
			style={style}
			ref={infoBoxRef}
		>
			<Card
				id={contentId}
				cardRef={contentRef}
				aria-label={tabs ? heading : undefined}
				aria-labelledby={tabs ? undefined : headingId}
			>
				{tabs ? <Card.Header tabs={headerNode} /> : <Card.Header>{headerNode}</Card.Header>}
				<Content padding="large">{content}</Content>
			</Card>
		</div>
	);

	return [button, infoBox];
}

interface LearnContentProps {
	/** Content columns to be displayed in the learn info box */
	columns: ReactNode[];
	/** Reverts the content order so columns are to the left and the illustration to the right */
	reverseContent?: boolean;
}

export function LearnContent(props: LearnContentProps): JSX.Element {
	const { columns, reverseContent } = props;
	return (
		<div
			data-component="learn-content"
			className={cn(scss.learnContentContainer, reverseContent && scss.reverseContent)}
		>
			<Illustration
				size="large"
				className={scss.illustration}
				src={IllustrationEducate}
				decorative
			/>
			{columns}
		</div>
	);
}
