import React from "react";
import scss from "./base-layout-new.scss";

export type BaseLayoutNewProps = {
	/** Elements to populate the base layout */
	children: React.ReactNode;
	/** ID for the main section of base layout */
	id: string;
	/** Optional top header area */
	topHeaderArea?: React.ReactNode;
	/** Optional page header element */
	pageHeader?: React.ReactNode;
	/** Padding for the content section */
	contentPadding?: "medium" | "none";
};

export function BaseLayoutNew(props: BaseLayoutNewProps): JSX.Element {
	const { id, pageHeader, topHeaderArea, children, contentPadding = "medium" } = props;

	return (
		<main data-component="base-layout" id={id} className={scss.main}>
			{topHeaderArea}
			{pageHeader}
			<div className={contentPadding && scss.paddingDefault}>{children}</div>
		</main>
	);
}
