import React, { useEffect, useState } from "react";
import {
	Button,
	Content,
	Divider,
	Icon,
	IconChevron,
	IconChevronWithLine,
	Tooltip,
	VisualComponent,
	cn,
} from "@siteimprove/fancylib";
import { Popover } from "../../overlay/popover/popover";
import { Form } from "../../forms-and-inputs/form/form";
import { FormElementWrapper } from "../../forms-and-inputs/form-element-wrapper/form-element-wrapper";
import { InputField } from "../../forms-and-inputs/input-field/input-field";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import * as scss from "./page-changer.scss";

export type PageChangerProps = {
	/** Total count of items */
	total: number;
	/** Selected page number */
	page: number;
	/** Callback to change the selected page */
	setPage: (page: number) => void;
	/** Selected page size */
	pageSize: number;
	/** Label for the cancel button */
	cancelLabel: string;
	/** Label for the confirm button */
	confirmLabel: string;
	/** Tooltip text for the first button */
	firstLabel: string;
	/** Tooltip text for the previous button */
	prevLabel: string;
	/** Tooltip text for the next button */
	nextLabel: string;
	/** Tooltip text for the last button */
	lastLabel: string;
	/** Label for page input */
	pageLabel: string;
	/** Label for page dropdown button - something like `Page ${current} of ${total} */
	pageXofYLabel: (current: number, total: number) => string;
	/** Default error for page number input */
	defaultError: string;
	/** Error for page number input when number is not integer */
	wholeNumberError: string;
	/** Error for page number input when number is out of bounds (below 1 and above total) */
	outOfBoundsError: (total: number) => string;
	/** Used as data observe key for pagination buttons */
	observeKeyPrefix?: string;
} & VisualComponent;

export function PageChanger(props: PageChangerProps): JSX.Element {
	const {
		total,
		page,
		setPage,
		pageSize,
		confirmLabel,
		cancelLabel,
		firstLabel,
		prevLabel,
		nextLabel,
		lastLabel,
		pageLabel,
		pageXofYLabel,
		defaultError,
		wholeNumberError,
		outOfBoundsError,
		observeKeyPrefix,
		className,
		...rest
	} = props;

	const totalPages = total > 0 && pageSize > 0 ? Math.ceil(total / pageSize) : 0;

	return (
		<div className={cn(className, scss.pageChanger)} {...rest}>
			<Button.Group>
				<Tooltip variant={{ type: "interactive" }} content={firstLabel}>
					<Button
						aria-label={firstLabel}
						disabled={page === 1}
						onClick={() => setPage(1)}
						data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-first-page`}
					>
						<Icon rotation="0">
							<IconChevronWithLine />
						</Icon>
					</Button>
				</Tooltip>
				<Tooltip variant={{ type: "interactive" }} content={prevLabel}>
					<Button
						aria-label={prevLabel}
						disabled={page === 1}
						onClick={() => setPage(Math.max(1, page - 1))}
						data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-previous-page`}
					>
						<Icon rotation="90">
							<IconChevron />
						</Icon>
					</Button>
				</Tooltip>
			</Button.Group>
			<PageChangerDropdown
				currentPage={page}
				totalPages={totalPages}
				onChange={setPage}
				cancelLabel={cancelLabel}
				confirmLabel={confirmLabel}
				pageLabel={pageLabel}
				pageXofYLabel={pageXofYLabel}
				defaultError={defaultError}
				wholeNumberError={wholeNumberError}
				outOfBoundsError={outOfBoundsError}
				observeKeyPrefix={observeKeyPrefix}
			/>
			<Button.Group>
				<Tooltip variant={{ type: "interactive" }} content={nextLabel}>
					<Button
						aria-label={nextLabel}
						disabled={page === totalPages}
						onClick={() => setPage(Math.min(page + 1, totalPages))}
						data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-next-page`}
					>
						<Icon rotation="270">
							<IconChevron />
						</Icon>
					</Button>
				</Tooltip>
				<Tooltip variant={{ type: "interactive" }} content={lastLabel}>
					<Button
						aria-label={lastLabel}
						disabled={page === totalPages}
						onClick={() => setPage(totalPages)}
						data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-last-page`}
					>
						<Icon rotation="180">
							<IconChevronWithLine />
						</Icon>
					</Button>
				</Tooltip>
			</Button.Group>
		</div>
	);
}

interface PageChangerDropdownProps {
	/** Currently selected page */
	currentPage: number;
	/** Total number of pages */
	totalPages: number;
	/** Callback for onChange event */
	onChange: (page: number) => void;
	/** Label for the cancel button */
	confirmLabel: string;
	/** Label for the confirm button */
	cancelLabel: string;
	/** Label for page input */
	pageLabel: string;
	/** Label for page dropdown button - something like `Page ${current} of ${total} */
	pageXofYLabel: (current: number, total: number) => string;
	/** Default error for page number input */
	defaultError: string;
	/** Error for page number input when number is not integer */
	wholeNumberError: string;
	/** Error for page number input when number is out of bounds (below 1 and above total) */
	outOfBoundsError: (total: number) => string;
	/** Disabled state */
	disabled?: boolean;
	/** Used as data observe key for pagination buttons */
	observeKeyPrefix?: string;
}

function PageChangerDropdown(props: PageChangerDropdownProps) {
	const {
		currentPage,
		onChange,
		totalPages,
		disabled,
		confirmLabel,
		cancelLabel,
		pageLabel,
		pageXofYLabel,
		defaultError,
		wholeNumberError,
		outOfBoundsError,
		observeKeyPrefix,
	} = props;

	const [inputVal, setInputVal] = useState(`${currentPage}`);

	const inputValid = isInteger(inputVal) && isWithinMinMax(inputVal);

	useEffect(() => {
		setInputVal(`${currentPage}`);
	}, [currentPage]);

	return (
		<Popover
			data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-page-number-dropdown`}
			popoverContent={(id, ref, { setIsOpen }) => (
				<div id={id}>
					<Content>
						<Form>
							<FormElementWrapper
								label={pageLabel}
								name="page"
								invalid={!inputValid}
								error={errorMessage(inputVal)}
							>
								<InputField
									ref={ref}
									aria-label={pageLabel}
									value={inputVal}
									onChange={setInputVal}
									onKeyDown={(e) => {
										if (inputValid && e.keyCode === KeyCode.Enter) {
											e.preventDefault();
											confirm(setIsOpen);
										}
									}}
								/>
							</FormElementWrapper>
						</Form>
					</Content>
					<Divider />
					<ActionBar
						primary={{
							children: confirmLabel,
							onClick: () => confirm(setIsOpen),
							disabled: !inputValid,
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-number-confirm-button`,
						}}
						cancel={{
							children: cancelLabel,
							onClick: () => {
								setIsOpen(false);
								setInputVal(`${currentPage}`);
							},
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-number-cancel-button`,
						}}
					/>
				</div>
			)}
			buttonContent={pageXofYLabel(currentPage, totalPages)}
			buttonProps={{ disabled }}
		/>
	);

	function confirm(setOpen: (x: boolean) => void) {
		onChange(+inputVal);
		setOpen(false);
	}

	function isInteger(val: string) {
		return val.match(/^\d+$/);
	}

	function isWithinMinMax(val: string) {
		if (!isInteger(val)) {
			return false;
		}

		return +val >= 1 && +val <= totalPages;
	}

	function errorMessage(val: string) {
		if (!isInteger(val)) {
			return wholeNumberError;
		}

		if (!isWithinMinMax(val)) {
			return outOfBoundsError(totalPages);
		}

		return defaultError;
	}
}
