import React, { useRef, useState } from "react";
import { InlineText, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import { IllustrationSuccess } from "@siteimprove/fancyvisuals";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { SortField } from "../../tables-and-lists/column-header/column-header";
import { invertDirection } from "../../tables-and-lists/table/table";
import { PageHeaderPickers } from "../../structure/page-header-pickers/page-header-pickers";
import { useData } from "../../../utils/data-fetch";
import { Site, SitePicker, colPages, colVisits } from "./site-picker";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Site picker",
	notepad: "https://hackmd.io/xHKW4tbtTiqWpz2SGtP5aA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Site picker" subTitle="Allow users to select a site." />
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={SitePicker} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<b>Default</b>: Button shows selected site or an empty placeholder text if no site is
					selected.
				</Paragraph>
				<Paragraph>
					<b>The site picker consists of two main elements</b>:
				</Paragraph>
				<Ul
					items={[
						"Button (with icon) to display the selected site.",
						"Popover (triggered by button click) with a table of sites and default columns with data related to the site.",
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Usage with no sites</Header.H3>
				<Paragraph>Used when data is unavailable.</Paragraph>
				<Example fn={UsageWithoutSites} />
				<Header.H3>Usage with many sites</Header.H3>
				<Paragraph>The load more button will load more sites.</Paragraph>
				<Example fn={UsageWithManySites} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={SitePicker}
					initialProps={{
						items: [],
						totalItems: 0,
						sort: { property: "name", direction: "asc" },
						setSort: () => {},
						search: { query: "", onSearch: () => {} },
						onLoadMore: () => {},
						editSitesUrl: "",
						onSelectedSite: () => {},
						onFavorite: () => {},
						selectedSite: undefined,
						texts: {
							selectASite: "Select a site",
							editSites: "Edit sites",
							searchPlaceholder: "Search for sites",
							favoriteAriaLabel: "Favorite",
							showingXOfYItems: (showing: number, total: number) =>
								`Showing ${showing} of ${total} sites`,
							loadMore: (count: number) => `Load ${count} more`,
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Do's</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Focus on the most important actions for the current context.",
							"Strictly adhere to Siteimprove's brand guidelines for colors, typography, and iconography.",
							"Use ample white space to maintain a clean and uncluttered appearance.",
							"Ensure a balanced distribution of elements throughout the header.",
							"Reveal additional options or actions as needed, rather than displaying everything at once.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Don'ts</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Avoid overloading the header with too many features, which can distract users from core tasks.",
							"Reserve alerts for truly critical, time-sensitive messages requiring immediate user attention.",
							"Prioritize essential actions and consider moving less-used ones to dropdown menus or other locations.",
							"Avoid hiding crucial actions behind unclear icons or gestures.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<InlineText>
						For developers, ensure the header is accessible by adhering to the following best
						practices:
					</InlineText>
					<Ul
						items={[
							`Breadcrumbs: Placed inside the <main> element. Make the breadcrumb container a <nav aria-label="Breadcrumbs">...</a>`,
							`Heading: Placed inside the <main> element. Make the heading an <h1> element.`,
							`Main content section: Use the <main> element for this (Note: A page must only have one <main> element). It must have id="content" and tabindex="-1" for the page's="Skip to main content" link to work.`,
							`Page toolbar: Placed inside the <main> element, This container should have role="group" and aria-label="Page toolbar"`,
							`Page filter: Placed inside the <main> element. This container should have role="group" and aria-label="Page filter"`,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph emphasis="strong">Page Titles:</Paragraph>
					<Ul
						items={[
							"Use the same label from the side navigation for consistency.",
							<>Keep them short, informative, and in sentence case (e.g., "Account Settings").</>,
						]}
					/>
					<Paragraph emphasis="strong">Button Labels:</Paragraph>
					<Ul
						items={[
							<>Employ action verbs (e.g., "Create," "Edit," "Delete").</>,
							<>
								Consult the <a href="https://fancy.siteimprove.com/Writing/word-list">Word list</a>{" "}
								for approved terminology.
							</>,
						]}
					/>
					<Paragraph emphasis="strong">Alert Messages:</Paragraph>
					<Ul
						items={[
							"Prioritize clarity and conciseness.",
							"Focus on the essential information users need to know.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	type SitesRequest = { query: string; page: number; pageSize: number; sortField: SortField<Site> };

	const [request, setRequest] = useState<SitesRequest>({
		query: "",
		page: 1,
		pageSize: 20,
		sortField: { property: "isFavorite", direction: "asc" },
	});

	const { api } = useDataAPI();

	const fetchDataFn = async (request: SitesRequest, signal?: AbortSignal) =>
		await api.getSites(request, signal);

	const { data, loading, triggerRender } = useData(fetchDataFn, request);
	const [selected, setSelected] = useState<Site>();

	return (
		<PageHeaderPickers
			items={
				data && (
					<SitePicker
						items={data.items}
						sort={request.sortField}
						setSort={(property, direction) =>
							setRequest({
								...request,
								sortField: {
									property,
									direction:
										property === request.sortField.property
											? invertDirection(request.sortField.direction)
											: direction,
								},
							})
						}
						search={{
							query: request.query,
							onSearch: (query) => setRequest({ ...request, query }),
						}}
						onLoadMore={() => setRequest((prev) => ({ ...prev, pageSize: prev.pageSize + 20 }))}
						totalItems={data.totalItems}
						selectedSite={selected}
						onSelectedSite={setSelected}
						onFavorite={async (site, isFavorite) => {
							await api.updateFavoriteSite(site, isFavorite);
							triggerRender();
						}}
						editSitesUrl="https://fancy.siteimprove.com/"
						loading={loading}
					/>
				)
			}
		/>
	);
};

const UsageWithoutSites = () => {
	return (
		<PageHeaderPickers
			items={
				<SitePicker
					items={[]}
					sort={{ property: "isFavorite", direction: "asc" }}
					setSort={() => {}}
					search={{
						query: "",
						onSearch: () => {},
					}}
					onLoadMore={() => {}}
					onSelectedSite={() => {}}
					onFavorite={() => {}}
					totalItems={0}
					editSitesUrl="https://fancy.siteimprove.com/"
				/>
			}
		/>
	);
};

/** @visualDiffDelay 5000 */
const UsageWithManySites = () => {
	type SitesRequest = { query: string; page: number; pageSize: number; sortField: SortField<Site> };

	const [request, setRequest] = useState<SitesRequest>({
		query: "",
		page: 1,
		pageSize: 20,
		sortField: { property: "isFavorite", direction: "asc" },
	});

	const { api } = useDataAPI();

	const fetchDataFn = async (request: SitesRequest, signal?: AbortSignal) => {
		await new Promise((resolve) => setTimeout(resolve, 2000));
		return await api.getSitesLarge(request, signal);
	};

	const { data, loading, triggerRender } = useData(fetchDataFn, request);
	const [selected, setSelected] = useState<Site>();

	const pickersLoading = loading && data === null;

	return (
		<PageHeaderPickers
			loading={pickersLoading}
			items={
				data && (
					<SitePicker
						items={data.items}
						loading={loading}
						sort={request.sortField}
						setSort={(property, direction) =>
							setRequest((prev) => ({
								...prev,
								sortField: {
									property,
									direction:
										property === request.sortField.property
											? invertDirection(request.sortField.direction)
											: direction,
								},
							}))
						}
						search={{
							query: request.query,
							onSearch: (query) => setRequest({ ...request, query }),
						}}
						onLoadMore={() => setRequest((prev) => ({ ...prev, pageSize: prev.pageSize + 20 }))}
						totalItems={data.totalItems}
						selectedSite={selected}
						onSelectedSite={setSelected}
						onFavorite={async (site, isFavorite) => {
							await api.updateFavoriteSiteLarge(site, isFavorite);
							triggerRender();
						}}
						editSitesUrl="https://fancy.siteimprove.com/"
						extraColumns={[colPages(), colVisits()]}
					/>
				)
			}
		/>
	);
};

const useDataAPI = () => {
	type SiteDto = Site & {
		pages: number | null;
		visits: number | null;
	};

	type SitesRequest = {
		query: string;
		page: number;
		pageSize: number;
		sortField: SortField<SiteDto>;
	};

	function getRandomIntInclusive(min: number, max: number) {
		const minCeiled = Math.ceil(min);
		const maxFloored = Math.floor(max);
		return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
	}

	const dataSourceRef = useRef<{ sites: SiteDto[]; sitesLarge: SiteDto[] }>({
		sites: [
			{ name: "Siteimprove.au", url: "http://siteimprove.au" },
			{
				name: "Siteimprove marketing web site: Built to help teams optimize reach, reputation, revenue, and returns",
				url: "https://www.siteimprove.com/platform/search-engine-marketing/competitive-research",
			},
			{ name: "Siteimprove.com", url: "http://siteimprove.com" },
			{ name: "Siteimprove.uk", url: "http://siteimprove.uk" },
		].map((x, i) => ({
			...x,
			id: 2002 + i,
			pages: getRandomIntInclusive(100, 1000),
			visits: getRandomIntInclusive(100, 600),
			isFavorite: i < 2,
			imageSrc: IllustrationSuccess,
		})),
		sitesLarge: Array.from({ length: 105 }, (_, i) => ({
			name: `Site ${i + 1}`,
			url: "https://fancy.siteimprove.com/",
		})).map((x, i) => ({
			...x,
			id: 5008 + i,
			pages: getRandomIntInclusive(100, 1000),
			visits: getRandomIntInclusive(100, 600),
			isFavorite: i < 8,
			imageSrc: IllustrationSuccess,
		})),
	});

	const sortSitesFn = (request: SitesRequest) => (a: SiteDto, b: SiteDto) => {
		if (request.sortField.property === "isFavorite") {
			return request.sortField.direction === "asc"
				? a.isFavorite === b.isFavorite
					? 0
					: a.isFavorite
					? -1
					: 1
				: b.isFavorite === a.isFavorite
				? 0
				: b.isFavorite
				? -1
				: 1;
		} else if (request.sortField.property === "name") {
			return request.sortField.direction === "asc"
				? a.name.localeCompare(b.name)
				: b.name.localeCompare(a.name);
		} else if (request.sortField.property === "url") {
			return request.sortField.direction === "asc"
				? a.url.localeCompare(b.url)
				: b.url.localeCompare(a.url);
		} else if (request.sortField.property === "pages") {
			return request.sortField.direction === "asc" ? a.pages! - b.pages! : b.pages! - a.pages!;
		} else if (request.sortField.property === "visits") {
			return request.sortField.direction === "asc" ? a.visits! - b.visits! : b.visits! - a.visits!;
		}

		return 0;
	};

	const getSites = async (request: SitesRequest, signal?: AbortSignal) => {
		if (signal?.aborted) {
			throw new DOMException("Aborted", "AbortError");
		}

		const items = dataSourceRef.current.sites;

		return {
			items: (request.query
				? items.filter((item) => {
						const queryLowerCase = request.query.toLowerCase();
						return (
							item.name.toLowerCase().includes(queryLowerCase) ||
							item.url.toLowerCase().includes(queryLowerCase)
						);
				  })
				: items.slice((request.page - 1) * request.pageSize, request.pageSize)
			).sort(sortSitesFn(request)),
			totalItems: items.length,
		};
	};

	const getSitesLarge = async (request: SitesRequest, signal?: AbortSignal) => {
		if (signal?.aborted) {
			throw new DOMException("Aborted", "AbortError");
		}

		const items = dataSourceRef.current.sitesLarge;

		return {
			items: (request.query
				? items.filter((item) => {
						const queryLowerCase = request.query.toLowerCase();
						return (
							item.name.toLowerCase().includes(queryLowerCase) ||
							item.url.toLowerCase().includes(queryLowerCase)
						);
				  })
				: items.slice((request.page - 1) * request.pageSize, request.pageSize)
			).sort(sortSitesFn(request)),
			totalItems: items.length,
		};
	};

	return {
		api: {
			getSites,
			getSitesLarge,
			updateFavoriteSite: async (site: Site, isFavorite: boolean) => {
				dataSourceRef.current.sites.find((x) => x.name === site.name)!.isFavorite = isFavorite;
			},
			updateFavoriteSiteLarge: async (site: Site, isFavorite: boolean) => {
				dataSourceRef.current.sitesLarge.find((x) => x.name === site.name)!.isFavorite = isFavorite;
			},
		},
	};
};
