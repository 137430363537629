import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
	Badge,
	DataObserveKey,
	Divider,
	FocusableComponent,
	Icon,
	IconChevron,
	IconDoubleChevron,
	Spinner,
	Tooltip,
	VisualComponent,
	cn,
	useUniqueId,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { SearchNavigation } from "../search-navigation/search-navigation";
import { dataObserveKeyDiscriminator } from "../../../utils/shorthands";
import { useMultiToggle, useVerticalExpandCollapse } from "../../../utils/hooks";
import { useDesignToken } from "../../context/theme/theme";
import {
	ItemLabel,
	LinkItem,
	NavigateFn,
	NavigationItem,
	isLinkItem,
} from "./side-navigation-utils";
import * as scss from "./side-navigation.scss";

export type { NavigationItem, ItemLabel };

export type SideNavigationProps = {
	mainItems: NavigationItem[];
	bottomItems?: NavigationItem[];
	selectionId: string | null;
	setSelectionId: (selectionId: string | null) => void;
	viewState?: ViewState;
	setViewState: (viewState: ViewState) => void;
	expandedItems?: string[];
	setExpandedItems: (expandedItems: string[]) => void;
	searchLabel?: string;
	searchPlaceholder?: string;
	searchHotkey?: string;
	collapseNavigationTooltipText?: string;
	expandNavigationTooltipText?: string;
	backButtonLabel?: string;
	backButtonUrl?: string;
	updateKeys?: React.DependencyList;
	/** Optional list of selectors to identify dialogs (e.g. modals and sidepanels) that should disable the hotkey used in SearchNavigation */
	dialogSelectors?: string[];
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export type ViewState = "expanded" | "collapsed" | "fixed";

type SideNavigationContextProps = {
	viewState: ViewState;
	expandedItems: string[];
	selectionId: string | null;
	subNavExpanded: boolean;
	showSelectionSpinner: boolean;
	mainFooterNavRef?: React.RefObject<HTMLUListElement>;
	handleMainNavButtonClick: NavigateFn<React.MouseEvent<HTMLButtonElement>>;
	handleSubNavButtonClick: NavigateFn<React.MouseEvent<HTMLButtonElement>>;
};

const SideNavigationContext = React.createContext<SideNavigationContextProps>({
	viewState: "expanded",
	expandedItems: [],
	selectionId: null,
	subNavExpanded: false,
	showSelectionSpinner: false,
	mainFooterNavRef: undefined,
	handleMainNavButtonClick: () => {},
	handleSubNavButtonClick: () => {},
});

const itemRefDictionary: { [id: string]: React.RefObject<HTMLElement> } = {};

export function SideNavigation(props: SideNavigationProps): JSX.Element {
	const {
		expandedItems,
		setExpandedItems,
		searchLabel,
		searchPlaceholder,
		searchHotkey,
		collapseNavigationTooltipText,
		expandNavigationTooltipText,
		backButtonLabel,
		backButtonUrl,
		mainItems,
		bottomItems,
		selectionId,
		setSelectionId,
		setViewState,
		updateKeys = [],
		dialogSelectors,
		className,
		style,
	} = props;

	// TODO For debugging – remove when component is finished
	const debug = false;

	// Some props have defaults or have to be "fixed up" before use
	const viewState = props.viewState || "expanded";

	// Only show main nav when there's more than one main nav item
	const supportMainNav = mainItems.length > 1;

	// Create an inner state to store a modified version of the expanded items, ensuring
	// the visibility of the selection. This state cannot be a simple variable as it requires
	// updates when the selection changes. To maintain consistency and address potential issues
	// with sub-navigation collapsing, the inner state is initialized with the "safe" version
	// of the expanded items (output from makeSafeExpansion). Running makeSafeExpansion on the
	// external expandedItems always leads to the issue where collapsing a sub-nav containing
	// the selected item becomes impossible.
	//
	// The useEffect below [1] is strategically placed to propagate changes from the external
	// expandedItems state to the inner state used within the component.
	//
	// The subsequent useEffect [2] is placed to ensure the selection is visible when the selection
	// changes.
	const [innerExpandedItems, setInnerExpandedItems] = useState(
		makeSafeExpansion(mainItems.concat(bottomItems || []), selectionId, expandedItems || [])
	);

	// propagate the expanded items to the parent component
	const updateExpandedItems = (expandedItems: string[]) => {
		setInnerExpandedItems(expandedItems);
		setExpandedItems(expandedItems);
	};

	// States
	const [showSelectionSpinner, setShowSelectionSpinner] = useMultiToggle(false);
	const [subNavExpanded, setSubNavExpanded] = useState(
		supportMainNav ? innerExpandedItems.length > 0 : true
	);
	const [subNavDisplayNone, setSubNavDisplayNone] = useState(false);
	const [noTransitionBetweenMainNavTopAndSubNav, setNoTransitionBetweenMainNavTopAndSubNav] =
		useState<boolean>();
	const [tooltipUpdateKey, setTooltipUpdateKey] = useState(0);

	if (debug) console.log("Prop, mainItems:", mainItems);
	if (debug) console.log("Prop, bottomItems:", bottomItems);
	if (debug) console.log("Prop, selectionId:", selectionId);
	if (debug) console.log("Prop (input), viewState:", props.viewState);
	if (debug) console.log("Prop (fixed), viewState:", viewState);
	if (debug) console.log("Prop (input), expandedItems:", expandedItems);
	if (debug) console.log("Prop (fixed), expandedItems:", innerExpandedItems);
	if (debug) console.log("State, showSelectionSpinner:", showSelectionSpinner);
	if (debug) console.log("State, subNavExpanded:", subNavExpanded);

	// Derived states
	const expandedView = viewState === "expanded" || viewState === "fixed";
	const fixedView = viewState === "fixed";
	const showingMainNav = !subNavExpanded || viewState === "collapsed";
	const showingSubNav = subNavExpanded && expandedView;

	if (debug) console.log("Derived state, showingMainNav:", showingMainNav);
	if (debug) console.log("Derived state, showingSubNav:", showingSubNav);

	// States initialized by a derived state
	const [mainNavTopHidden, setMainNavTopHidden] = useState(showingSubNav);
	const [subNavHidden, setSubNavHidden] = useState(!showingSubNav);

	// [1] Propagate the expanded items to the inner state when the prop changes
	useEffect(() => setInnerExpandedItems(expandedItems || []), [expandedItems]);

	// [2] Update expanded items when the selection changes to ensure the selection is visible
	useEffect(() => {
		const updatedExpandedItems = makeSafeExpansion(
			mainItems.concat(bottomItems || []),
			selectionId,
			innerExpandedItems
		);
		updateExpandedItems(updatedExpandedItems);
		setSubNavExpanded(supportMainNav ? updatedExpandedItems.length > 0 : true);

		setMainNavTopHidden(subNavExpanded);
		setSubNavHidden(!subNavExpanded);
	}, [selectionId, ...updateKeys]);

	// This effect briefly adds "display: none" to the subNav on first render and when selectionId is changed if the viewState is "collapsed" and subNav is expanded
	// This fixes a CSS rendering issue that occurs when using a combination of visibility, transform and transition
	useEffect(() => {
		if (!expandedView && subNavExpanded) {
			setSubNavDisplayNone(true);
			requestAnimationFrame(() => {
				setSubNavDisplayNone(false);
			});
		}
	}, [selectionId]);
	// This effect sets states based on derived states when subNavExpanded changes
	useEffect(() => {
		setSubNavHidden(supportMainNav ? showingMainNav : false);
	}, [subNavExpanded]);

	const mainFooterNavRef = useRef<HTMLUListElement>(null);

	// Shared context
	const context: SideNavigationContextProps = {
		viewState,
		expandedItems: innerExpandedItems,
		subNavExpanded,
		selectionId,
		showSelectionSpinner,
		mainFooterNavRef,
		handleMainNavButtonClick,
		handleSubNavButtonClick,
	};

	// We only want to show bottom items that you can click on. So if the bottom item is clicked
	// and have children AND if the sub nav is shown, then exclude this one.
	const bottomItemsToRender =
		bottomItems?.filter((item) => !(isItemExpanded(context, item.id) && subNavExpanded)) || [];
	// Some bottom items can be hidden and still be clickable programmatically,
	// so we need to keep them in the list and render them in the DOM.
	// Also need to know the amount of visible bottom items to calculate the height of the sub nav.
	const visibleBottomItems = bottomItemsToRender.filter((item) => item.visible !== false);

	if (debug) console.log("Derived state, bottomItemsToRender:", bottomItemsToRender);

	// Refs
	const sideNavigationRef = useRef<HTMLDivElement>(null);
	const mainNavTopRef = useRef<HTMLUListElement>(null);
	const expandedMainItemRef = useRef<HTMLLIElement>(null);
	const subNavRef = useRef<HTMLDivElement>(null);
	const backButtonRef = useRef<HTMLButtonElement>(null);

	// IDs
	const shownTitleId = useUniqueId("shown-title");

	// Action functions
	function viewStateToggleClick() {
		if (debug) console.log("View state toggle clicked");
		setViewState(viewState == "expanded" ? "collapsed" : "expanded");
		setNoTransitionBetweenMainNavTopAndSubNav(true);
		setMainNavTopHidden(viewState === "expanded" ? false : subNavExpanded);
		setSubNavHidden(viewState === "expanded" ? true : !subNavExpanded);

		callbackOnTransitionEnd(sideNavigationRef.current, "width", () => {
			setTooltipUpdateKey((prev) => prev + 1);
		});
	}

	function handleBackButtonClick() {
		if (debug) console.log("Back button clicked");
		setNoTransitionBetweenMainNavTopAndSubNav(false);
		setSubNavExpanded(false);
		setMainNavTopHidden(false);
		callbackOnTransitionEnd(mainNavTopRef.current, "transform", () => {
			setSubNavHidden(true);
			expandedMainItemRef.current?.firstChild &&
				(expandedMainItemRef.current?.firstChild as HTMLElement).focus();
		});
		expandedMainItemRef.current?.scrollIntoView({ block: "center" });
	}

	function handleMainNavButtonClick(item: NavigationItem, e: React.MouseEvent<HTMLButtonElement>) {
		if (debug) console.log("Main nav clicked", item);

		if (!itemHasChildren(item)) {
			updateExpandedItems([]);
			handleNavItemClick(item, e);
			return;
		}

		setViewState("expanded");
		setSubNavExpanded(true);
		setSubNavHidden(false);

		if (!isItemExpanded(context, item.id)) {
			updateExpandedItems([item.id]);
		}

		// This condition means that the user has clicked one of the bottomItems when the subNav is shown
		// Since there's no transition in this case we don't want to listen for a "transitionend" event or make changes to the mainNavTop
		if (subNavExpanded) {
			postTransition();
		} else {
			callbackOnTransitionEnd(subNavRef.current, "transform", () => {
				setMainNavTopHidden(true);
				postTransition();
			});
		}

		function postTransition() {
			backButtonRef?.current?.focus();

			setSelectionId(null);
			if (item.children && item.defaultChildId) {
				const expansions = getExpandedItems(item.children, item.defaultChildId);
				const defaultChild = findItem(item.children, item.defaultChildId);
				if (defaultChild) {
					handleNavItemClick(defaultChild, e);
					updateExpandedItems([item.id, ...expansions]);
					if (isLinkItem(defaultChild) && defaultChild.href && !defaultChild.onClick) {
						// Skip navigation while debugging
						if (!debug) window.location.href = defaultChild.href;
					}
				}
			}
		}
	}

	function handleSubNavButtonClick(item: NavigationItem, e: React.MouseEvent<HTMLButtonElement>) {
		if (debug) console.log("Sub nav clicked", item);

		if (!itemHasChildren(item)) return handleNavItemClick(item, e);

		const willCollapse = isItemExpanded(context, item.id);

		updateExpandedItems(
			willCollapse
				? innerExpandedItems.filter((val) => val !== item.id) // collapse
				: innerExpandedItems.concat(item.id) // expand
		);
	}

	async function handleNavItemClick(
		item: NavigationItem,
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
	) {
		if (debug) console.log("Nav item clicked", item);
		// Support keyboard shortcut to open in a new tab on Mac and Windows
		if (isLinkItem(item) && (e.metaKey || e.ctrlKey)) {
			window.open(item.href, "_blank");
			e.preventDefault();
			return;
		}
		setSelectionId(item.id);
		setShowSelectionSpinner(true);
		const onClick = (item as LinkItem).onClick;
		if (onClick) {
			if (isLinkItem(item)) {
				e.preventDefault();
			}
			try {
				await onClick();
			} finally {
				setShowSelectionSpinner(false);
			}
		} else if (isLinkItem(item)) {
			e.preventDefault();
			if (item.openNew) {
				window.open(item.href, "_blank");
				setShowSelectionSpinner(false);
			} else {
				if (debug) return; // Skip navigation while debugging
				window.location.href = item.href;
			}
		}
	}

	function handleSearchNavigationClick(
		item: NavigationItem,
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
	) {
		// Support shortcut to open in a new tab and in that case prevent default behavior and end function execution
		if (isLinkItem(item) && (e.metaKey || e.ctrlKey)) {
			window.open(item.href, "_blank");
			e.preventDefault();
			return;
		}

		const itemInMainItems = mainItems.some((mainItem) => mainItem.id === item.id);

		if (itemInMainItems && subNavExpanded) {
			setSubNavExpanded(false);
			setMainNavTopHidden(false);
			callbackOnTransitionEnd(mainNavTopRef.current, "transform", () => {
				setSubNavHidden(true);
			});
		} else if (!itemInMainItems && !subNavExpanded) {
			setSubNavExpanded(true);
			setSubNavHidden(false);
			callbackOnTransitionEnd(subNavRef.current, "transform", () => {
				setMainNavTopHidden(true);
			});
		}

		handleNavItemClick(item, e);
	}

	// This variable is used to calculate the height of the .main-nav-bottom element which is then subtracted from the .sub-nav element so that they don't overlap
	const mainNavBottomHeight = `${visibleBottomItems.length} * ${scss.navItemHeight} - ${
		visibleBottomItems.length
	} * ${scss.mainNavItemMargin} - ${scss.mainNavItemMargin} ${
		visibleBottomItems.length ? `- ${scss.mainNavItemMargin}` : ``
	}`;

	// Translation strings and translation props handled here
	const i18n = useLabTranslations();
	const collapseText = collapseNavigationTooltipText
		? collapseNavigationTooltipText
		: i18n.collapseNavigation;
	const expandText = expandNavigationTooltipText
		? expandNavigationTooltipText
		: i18n.expandNavigation;
	const backButtonText = backButtonLabel ? backButtonLabel : i18n.backToMainNavigation;

	return (
		<SideNavigationContext.Provider value={context}>
			<div
				ref={sideNavigationRef}
				className={cn(scss.sideNavigation, className, expandedView && scss.navExpanded)}
				style={style}
				data-component="SideNavigation"
				data-observe-key={props["data-observe-key"]}
			>
				<div className={scss.searchField}>
					<div className={cn(scss.searchNavigation, !expandedView && scss.displayNone)}>
						<SearchNavigation
							items={mainItems.concat(bottomItems ?? [])}
							label={searchLabel}
							placeholder={searchPlaceholder}
							hotkey={searchHotkey}
							data-observe-key={dataObserveKeyDiscriminator(props["data-observe-key"], "Search")}
							navigate={handleSearchNavigationClick}
							dialogSelectors={dialogSelectors}
						/>
					</div>
					{/* Don't show the viewStateToggle button when viewState is "fixed" */}
					{!fixedView && (
						<Tooltip
							variant={{ type: "interactive" }}
							content={viewState === "expanded" ? collapseText : expandText}
							aria-describedby=""
							placement="right"
							className={scss.viewStateToggleTooltip}
							noDelay
							updateKeys={[tooltipUpdateKey]}
						>
							<button
								className={scss.viewStateToggle}
								aria-label={collapseText}
								aria-pressed={viewState === "collapsed"}
								onClick={viewStateToggleClick}
								data-observe-key={dataObserveKeyDiscriminator(
									props["data-observe-key"],
									"ViewStateToggle",
									viewState === "expanded" ? "Collapse" : "Expand"
								)}
							>
								<Icon rotation={viewState === "collapsed" ? "180" : "0"} size="medium">
									<IconDoubleChevron />
								</Icon>
							</button>
						</Tooltip>
					)}
				</div>
				<Divider className={scss.searchFieldDivider} />
				<div className={scss.navContainer}>
					<div className={scss.mainNav}>
						<ul
							ref={mainNavTopRef}
							className={cn(
								scss.mainNavTop,
								showingMainNav && scss.shown,
								mainNavTopHidden && scss.hidden,
								noTransitionBetweenMainNavTopAndSubNav && scss.noTransition
							)}
						>
							{mainItems.map((item) => {
								const isMainNavItemActive =
									isItemExpanded(context, item.id) || isItemSelected(context, item.id);
								return (
									<MainNavItem
										{...item}
										key={item.id}
										ref={isMainNavItemActive ? expandedMainItemRef : undefined}
									/>
								);
							})}
						</ul>
						<div
							className={cn(
								scss.subNav,
								showingSubNav && scss.shown,
								subNavHidden && scss.hidden,
								subNavDisplayNone && scss.displayNone,
								noTransitionBetweenMainNavTopAndSubNav && scss.noTransition
							)}
							style={{
								height:
									visibleBottomItems.length > 0
										? `calc(100% - ${scss.mainNavItemMargin} - ${mainNavBottomHeight})`
										: undefined,
							}}
							ref={subNavRef}
							role="group"
							aria-labelledby={shownTitleId}
						>
							{supportMainNav ? (
								<button
									className={scss.backButton}
									onClick={handleBackButtonClick}
									data-observe-key={dataObserveKeyDiscriminator(
										props["data-observe-key"],
										"BackButton"
									)}
									ref={backButtonRef}
								>
									<Icon size="medium" rotation="90">
										<IconChevron />
									</Icon>
									{backButtonText}
								</button>
							) : (
								backButtonUrl && (
									<a
										className={cn(scss.backButton, scss.backLink)}
										href={backButtonUrl}
										data-observe-key={dataObserveKeyDiscriminator(
											props["data-observe-key"],
											"BackButton"
										)}
									>
										<Icon size="medium" rotation="90">
											<IconChevron />
										</Icon>
										{backButtonLabel}
									</a>
								)
							)}
							<div className={scss.subNavContainer}>
								{mainItems.map((item) => (
									<SubNavGroup
										{...item}
										key={item.id}
										shownTitleId={shownTitleId}
										show={isItemExpanded(context, item.id) || !supportMainNav}
									/>
								))}
								{bottomItems?.map((item) => (
									<SubNavGroup
										{...item}
										key={item.id}
										shownTitleId={shownTitleId}
										show={isItemExpanded(context, item.id) || !supportMainNav}
									/>
								))}
							</div>
						</div>
						{bottomItemsToRender.length > 0 && (
							<>
								<Divider className={scss.mainNavDivider} />
								<ul
									className={cn(scss.mainNavBottom, visibleBottomItems.length === 0 && scss.hidden)}
									ref={mainFooterNavRef}
								>
									{bottomItemsToRender.map((item) => {
										const isMainNavItemActive =
											isItemExpanded(context, item.id) || isItemSelected(context, item.id);
										return (
											<MainNavItem
												{...item}
												key={item.id}
												ref={isMainNavItemActive ? expandedMainItemRef : undefined}
											/>
										);
									})}
								</ul>
							</>
						)}
					</div>
				</div>
			</div>
		</SideNavigationContext.Provider>
	);
}

const MainNavItem = React.forwardRef<HTMLLIElement, NavigationItem>(
	(props: NavigationItem, ref): JSX.Element => {
		const { icon, id, observeKey, title, labels, visible } = props;
		const context = React.useContext(SideNavigationContext);
		const { handleMainNavButtonClick, showSelectionSpinner, viewState } = context;

		const isMainNavItemActive = isItemExpanded(context, id) || isItemSelected(context, id);

		const commonProps = {
			className: cn(scss.mainNavItem, isMainNavItemActive && scss.active),
			"data-observe-key": observeKey,
			onClick: (e: React.MouseEvent<HTMLButtonElement>) => handleMainNavButtonClick(props, e),
			itemId: id,
		};

		const renderAsLink = isLinkItem(props) && !itemHasChildren(props);
		const wrapperProps: Omit<ItemWrapperProps, "children"> = renderAsLink
			? {
					type: "a",
					...{
						...commonProps,
						href: props.href,
						target: props.openNew ? "_blank" : undefined,
					},
			  }
			: {
					type: "button",
					...commonProps,
			  };

		const expandedContent: JSX.Element = (
			<>
				<div>{renderIcon(icon)}</div>
				<div className={scss.mainNavTitle}>
					<ItemTitle title={title} labels={labels} />
				</div>
				{!itemHasChildren(props) && isItemSelected(context, id) && showSelectionSpinner && (
					<SpinnerElement />
				)}
				{itemHasChildren(props) && (
					<span className={scss.chevron}>
						<Icon size="medium" rotation="270">
							<IconChevron />
						</Icon>
					</span>
				)}
			</>
		);

		const collapsedContent: JSX.Element = (
			<>
				{!(isItemSelected(context, id) && showSelectionSpinner) && renderIcon(icon)}
				{isItemSelected(context, id) && showSelectionSpinner && <SpinnerElement />}
			</>
		);

		return (
			<li className={cn(scss.mainNavListItem, visible === false && scss.hidden)} ref={ref}>
				{/* Item in the collapsed main nav */}
				{viewState === "collapsed" && (
					<Tooltip variant={{ type: "interactive" }} content={title} placement="right">
						<ItemWrapper
							aria-label={title}
							{...wrapperProps}
							isSelected={isItemSelected(context, id)}
						>
							{collapsedContent}
						</ItemWrapper>
					</Tooltip>
				)}
				{/* Item or nav group with sub nav in the expanded main nav */}
				{(viewState === "expanded" || viewState === "fixed") && (
					<ItemWrapper {...wrapperProps}>{expandedContent}</ItemWrapper>
				)}
			</li>
		);
	}
);

type SubNavGroupProps = NavigationItem & {
	shownTitleId: string;
	show: boolean;
};

function SubNavGroup(props: SubNavGroupProps): JSX.Element | null {
	const { children, icon, title, labels, shownTitleId, show } = props;

	if (!itemHasChildren(props)) {
		return null;
	}

	return (
		<div className={cn(scss.subNavGroup, show && scss.shown)}>
			<div className={scss.subNavIconTitle}>
				{renderIcon(icon)}
				<div className={scss.subNavTitle}>
					<ItemTitle title={title} id={show ? shownTitleId : undefined} labels={labels} />
				</div>
			</div>
			<Divider className={scss.subNavTitleDivider} />
			<ul className={scss.subNavList}>
				{children?.map((item, index) => (
					<li key={index}>
						<SubNavItem {...item} />
					</li>
				))}
			</ul>
		</div>
	);
}

type ItemLabelProps = {
	title: string;
	id?: string;
	labels?: ItemLabel[];
};

function ItemTitle(props: ItemLabelProps): JSX.Element {
	const { title, id, labels } = props;
	const { ColorBlack } = useDesignToken();
	return (
		<>
			{id ? <span id={id}>{title}</span> : title}
			{labels && (
				<span className={scss.itemLabels}>
					{labels.map((label, i) => (
						<span key={i} title={label.tooltip} className={scss.itemLabel}>
							<Badge
								type={label.type}
								variant="light"
								size="small"
								style={{ verticalAlign: "middle" }}
							>
								{label.icon && (
									<Icon fill={ColorBlack} size="small">
										{label.icon}
									</Icon>
								)}
								{label.text}
							</Badge>
						</span>
					))}
				</span>
			)}
		</>
	);
}

function SubNavItem(props: NavigationItem): JSX.Element {
	const { children, id, observeKey, title, labels } = props;
	const context = React.useContext(SideNavigationContext);
	const { showSelectionSpinner, handleSubNavButtonClick } = context;

	const isSelected = isItemSelected(context, id);
	const isExpanded = isItemExpanded(context, id);
	const subNavId = useUniqueId("subNav");
	const nestedListRef = useRef<HTMLUListElement>(null);
	// We only want transition on user interaction and not on initial load
	const [isTransitionEnabled, setIsTransitionEnabled] = useState(false);

	const commonProps = {
		className: cn(scss.subNavItem, isSelected && scss.active),
		"data-observe-key": observeKey,
		onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
			setIsTransitionEnabled(true);
			// call the click handler
			handleSubNavButtonClick(props, e);
		},
	};

	useVerticalExpandCollapse(nestedListRef, isExpanded, isTransitionEnabled);

	const leafCommonProps = {
		...commonProps,
		itemId: id,
		"aria-current": isSelected ? ("page" as const) : undefined,
	};

	const wrapperProps: Omit<ItemWrapperProps, "children"> = isLinkItem(props)
		? {
				type: "a",
				...{
					...leafCommonProps,
					href: props.href,
					target: props.openNew ? "_blank" : undefined,
				},
		  }
		: {
				type: "button",
				...leafCommonProps,
		  };

	return (
		<>
			{/* Item in the sub nav */}
			{!itemHasChildren(props) && (
				<ItemWrapper {...wrapperProps} isSelected={isItemSelected(context, id)}>
					<ItemTitle title={title} labels={labels} />
					{isItemSelected(context, id) && showSelectionSpinner && <SpinnerElement />}
				</ItemWrapper>
			)}
			{/* Nav group with nested nav in the sub nav */}
			{itemHasChildren(props) && (
				<>
					<button {...commonProps} aria-expanded={isExpanded} aria-controls={subNavId}>
						<ItemTitle title={title} labels={labels} />
						<Icon size="medium" rotation={isExpanded ? "180" : "0"}>
							<IconChevron />
						</Icon>
						{isItemSelected(context, id) && showSelectionSpinner && <SpinnerElement />}
					</button>
					<ul ref={nestedListRef} id={subNavId} className={cn(scss.subNavNested)}>
						{children?.map((item) => (
							<li key={item.id}>
								<SubNavItem {...item} />
							</li>
						))}
					</ul>
				</>
			)}
		</>
	);
}

type ItemWrapperProps = {
	type: "a" | "button";
	itemId: string;
	children: ReactNode;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	href?: string;
	target?: string;
	isSelected?: boolean;
	"aria-current"?: "page";
	"aria-label"?: string;
} & DataObserveKey &
	VisualComponent;

function ItemWrapper(props: ItemWrapperProps): JSX.Element {
	const { children, type, isSelected, itemId, ...wrapperProps } = props;
	const itemRef = React.createRef<HTMLElement>();

	const item = React.createElement(type, { ...wrapperProps, ref: itemRef }, children);
	itemRefDictionary[itemId] = itemRef;

	const { mainFooterNavRef } = React.useContext(SideNavigationContext);

	// if the item is selected, make sure it's visible in the list
	// by scrolling it into view
	useEffect(() => {
		if (isSelected)
			scrollToSelectedItem(
				itemRefDictionary[itemId].current ?? undefined,
				mainFooterNavRef?.current ?? undefined
			);
	}, [isSelected]);

	return item;
}

function scrollToSelectedItem(target?: HTMLElement, footer?: HTMLUListElement) {
	setTimeout(() => {
		const targetTop = target?.getBoundingClientRect().top || 0;
		const footerTop = footer?.getBoundingClientRect().top || 0;

		const isVisible = targetTop <= footerTop;

		if (!isVisible) target?.scrollIntoView({ block: "center" });
	}, 500);
}

// Returns a list of all items that should be expanded to show a selected item
function getExpandedItems(items: NavigationItem[], selectedItemId: string): string[] {
	const result = [] as string[];

	// Main recursive function that scans through items. Return "true" if it finds the selection and adds it to the "result" list
	function run(items: NavigationItem[], selectedItemId: string): boolean {
		for (const item of items) {
			if (item.id === selectedItemId) {
				return true;
			}

			if (!item.children) return false;

			if (run(item.children, selectedItemId)) {
				result.push(item.id);
				return true;
			}
		}

		return false;
	}

	run(items, selectedItemId);
	return result;
}

// Generate a "safe" version of the requested expansion where we are sure the selection is visible
function makeSafeExpansion(
	allItems: NavigationItem[],
	selectionId: string | null,
	requestedExpansion: string[] | undefined
): string[] {
	// If there is no selection, just go with the requested expansion
	if (selectionId == null) {
		return requestedExpansion || [];
	}

	// Generate the minimal expansion needed for the selected item
	const defaultExpansion = (selectionId && getExpandedItems(allItems, selectionId)) || [];

	// If we have a selection and the minimal expansion is empty, it means we've on a root level item.
	// In this case, we should not have any expansion to make it visible.
	if (selectionId != null && defaultExpansion.length === 0) {
		return [];
	}

	// If an expansion was requested, check if the selection will be visible with it before using it.
	if (
		requestedExpansion != undefined &&
		defaultExpansion.every((v) => requestedExpansion!.includes(v))
	) {
		return requestedExpansion;
	}

	// Fallback to the minimal expansion
	return defaultExpansion;
}

// Recursively looks through the tree to find the item
function findItem(items: NavigationItem[], id: string): NavigationItem | null {
	for (const item of items) {
		if (item.id == id) return item;
		const child = item.children && findItem(item.children, id);
		if (child) return child;
	}

	return null;
}

function itemHasChildren(item: NavigationItem) {
	return item.children && item.children?.length !== 0;
}

// Helper functions
function isItemExpanded(context: SideNavigationContextProps, id: string) {
	const { expandedItems } = context;
	return expandedItems.indexOf(id) >= 0;
}

function isItemSelected(context: SideNavigationContextProps, id: string) {
	const { selectionId } = context;
	return selectionId === id;
}

const SpinnerElement = (): JSX.Element => (
	<div className={scss.spinner}>
		<Spinner variant={"light"}></Spinner>
	</div>
);

const renderIcon = (icon: NavigationItem["icon"]) =>
	icon && <div className={cn(scss.sideNavIcon)} dangerouslySetInnerHTML={{ __html: icon }} />;

// Run callback on the "transitionend" event for a specified element with CSS transition
function callbackOnTransitionEnd(
	elementWithTransition: HTMLElement | null | undefined,
	propertyWithTransition: string,
	callback: () => void
) {
	if (!elementWithTransition) return;
	const handler = function (e: TransitionEvent) {
		if (e.target === elementWithTransition && e.propertyName === propertyWithTransition) {
			callback();
			elementWithTransition.removeEventListener("transitionend", handler);
		}
	};
	elementWithTransition.addEventListener("transitionend", handler);
}
