import React from "react";
import { dataObserveKeyDiscriminator } from "../../../utils/shorthands";
import { PopoverProps } from "../../overlay/popover/popover";
import { ActiveFilters } from "./active-filters";
import { FilterGroup, FilterGroupProps, FilterItemProps } from "./filter-group";
import { SingleFilter, SingleFilterProps } from "./single-filter";

export type { FilterItemProps, FilterGroupProps, SingleFilterProps };

// eslint-disable-next-line @typescript-eslint/ban-types
export function useFilterGroup<T extends {}>(
	value: T,
	onChange: (newState: T) => void,
	filters: FilterGroupProps<T>["filters"],
	overrides?: Partial<PopoverProps>
): [filterButton: React.ReactElement, activeFilters: React.ReactElement | null] {
	const defaultState = buildDefaultState(filters, value);
	const activeFilters = filters.filter(isActive);
	const filtersWithPills = activeFilters.filter((filter) => isNonEmpty(filter, value));

	return [
		<FilterGroup
			emptyState={defaultState}
			filters={activeFilters}
			key="filter-group"
			onChange={onChange}
			value={value}
			{...overrides}
		/>,
		filtersWithPills.length ? (
			<ActiveFilters
				filters={filtersWithPills}
				data-observe-key={dataObserveKeyDiscriminator(
					overrides?.["data-observe-key"],
					"ActiveFilters"
				)}
				key={`active-filters-${Math.random()}`}
				onClearFilter={(property) =>
					onChange({
						...value,
						[property]: defaultState[property as keyof T],
					})
				}
				onClearAll={() => onChange(defaultState)}
				value={value}
			/>
		) : null,
	];
}

export function useSingleFilter<T>(
	value: SingleFilterProps<T>["value"],
	onChange: SingleFilterProps<T>["onChange"],
	filterProps: Omit<SingleFilterProps<T>, "value" | "onChange">
): [filterButton: React.ReactElement | null, activeFilters: React.ReactElement | null] {
	const { stringify, ...restFilterProps } = filterProps;

	const key = "single-filter";
	const filterWrapper = { [key]: value };
	type FilterWrapper = typeof filterWrapper;

	const filters: FilterItemProps<FilterWrapper>[] = [
		{
			property: key,
			stringify: (v) => stringify((v as FilterWrapper)[key] as T),
			...restFilterProps,
		} as FilterItemProps<FilterWrapper>,
	];

	const defaultState = buildDefaultState(filters, filterWrapper);
	const isFilterActive = isActive(filters[0]);
	const isPillVisible = isFilterActive && isNonEmpty(filters[0], filterWrapper);

	return [
		isFilterActive ? (
			<SingleFilter {...filterProps} key="single-filter" onChange={onChange} value={value} />
		) : null,
		isPillVisible ? (
			<ActiveFilters
				filters={filters}
				key="active-filters"
				value={filterWrapper}
				onClearFilter={() => onChange(defaultState[key])}
			/>
		) : null,
	];
}

function buildDefaultState<T>(filters: FilterGroupProps<T>["filters"], state: T): T {
	return Object.fromEntries(
		filters.map((filter) => [
			filter.property,
			filter.defaultOption !== undefined
				? filter.defaultOption
				: Array.isArray(state[filter.property])
				? []
				: undefined,
		])
	) as T;
}

function isActive<T>(filter: FilterItemProps<T>): boolean {
	return "active" in filter ? !!filter.active : true;
}

function isNonEmpty<T>(filter: FilterItemProps<T>, value: T): boolean {
	return !(Array.isArray(value[filter.property])
		? (value[filter.property] as Array<unknown>).length === 0
		: value[filter.property] === undefined);
}
