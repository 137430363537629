import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Icon,
	IconHelp,
	IconSettings,
	IconUser,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	// TODO Add Knobs again
	// Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionMenu } from "../../actions-and-controls/action-menu/action-menu";
import { HorizontalNavigation } from "./horizontal-navigation";

export const Meta: DocPageMeta = {
	category: "Navigation", // Which category does the component belong to?,
	title: "Horizontal navigation", // Should the name in the styleguide menu be different from the component name?,
	notepad: "https://hackmd.io/xHKW4tbtTiqWpz2SGtP5aA", //Go to https://hackmd.io/ , create an account, and create a new note. Provide the url to the note here.
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Horizontal navigation"
			subTitle="A persistent, top-level navigation element that provides quick access to essential site-wide utilities and global actions, and reinforces brand identity."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={HorizontalNavigation} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					This layout is the standard for Siteimprove products and integrations.
				</Paragraph>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Logo</b>: Reinforces brand identity and provides a link back to the homepage.
						</>,
						<>
							<b>Actions</b>:Provide quick access to essential system-level actions (e.g., Sign out,
							Notifications, Profile, Settings, Account switching) through a
							<GatsbyLink to="/lib/components/Actions and controls/Button">button</GatsbyLink>, with
							a sub-menu (
							<GatsbyLink to="/lab/components/Actions and controls/Action menu">
								Action Menu
							</GatsbyLink>
							) for additional actions if needed. The sub-menu opens on click and closes on
							selection or by clicking outside.
						</>,
						<>
							<b>Skip to content:</b> ( ): The "Skip to content" link allows keyboard users to
							navigate directly to the main content using the "Tab" key.
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H2>Properties</Header.H2>
				{/* TODO Add Knobs again */}
				{/* <Knobs component={HorizontalNavigation} initialProps={{ value: "Some value" }} /> */}
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>Principle</Header.H4>
					<Ul
						items={[
							<>
								<b>Recognition over recall</b>: The <Code>HorizontalNavigation</Code> should present
								familiar options, making it easy for users to find what they need without having to
								remember specific locations.
							</>,
							<>
								<b>Affordances</b>: Icons and labels should clearly communicate their function (e.g.
								a gear icon for settings).
							</>,
							<>
								<b>Consistency</b>: The <Code>HorizontalNavigation</Code> should maintain a
								consistent appearance and behavior across all pages and sections.
							</>,
						]}
					/>
					<Header.H4>General</Header.H4>
					<Ul
						items={[
							<>
								Use the <Code>HorizontalNavigation</Code> for essential, high-level actions that
								users will need frequently throughout their experience.
							</>,
							"Prioritize actions that are global in scope (e.g., sign-in, account settings).",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>HorizontalNavigation</Code> is typically used in the top of the page, e.g
						Sitimprove’s platform, CMS plugins, Browser extensions.
					</Paragraph>

					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>HorizontalNavigation</Code> to existing components for visual
								consistency.
							</>,
							<>
								Consider using a "sticky" or fixed position so the bar remains visible as users
								scroll.
							</>,
							<>
								Use clear, recognizable{" "}
								<GatsbyLink to="/lib/components/Visuals/Icon">Icons</GatsbyLink>. Pair with a
								concise <GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip</GatsbyLink> if
								space allows.
							</>,
							<>
								Use{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Button">
									Icon-only buttons
								</GatsbyLink>{" "}
								for global system-level utilities to reduce visual clutter.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Actions specific to a page or feature.",
							<>
								The navigation structure is complex and requires multiple levels of sub-menus. Use{" "}
								<GatsbyLink to="/lab/components/Navigation/Side navigation">
									Side Navigation{" "}
								</GatsbyLink>
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between icons, text, and the background. ",
							"People with visual impairments may not see the icon at all, so the button's label or aria-label must accurately convey its purpose.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>
						<b>Link labels</b>:
					</Paragraph>
					<Ul
						items={[
							<>Keep labels short and actionable (e.g., "Sign out", or "Notifications").</>,
							"Use sentence case consistently.",
							"Avoid abbreviations and technical jargon.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const siteImproveLogo = (
	<svg
		width="1108"
		height="350"
		viewBox="0 0 1108 350"
		fill="none"
		focusable="false"
		aria-hidden="true"
	>
		<g clipPath="url(#clip0_4153_96768)">
			<path
				d="M308.3 200.8L324.4 191.4C327.9 200.4 334.6 206 346.4 206C357.8 206 361.7 201.1 361.7 195.8C361.7 188.7 355.3 185.9 341.1 181.8C326.5 177.5 312.3 171.5 312.3 153.3C312.3 135.2 327.3 124.9 343.7 124.9C359.5 124.9 371.5 132.9 377.9 146.3L362.1 155.4C358.6 148.1 353.4 143.1 343.7 143.1C335.8 143.1 331.1 147.2 331.1 152.7C331.1 158.6 334.7 161.7 349.3 166.1C364.5 171 380.6 176 380.6 195.6C380.6 213.5 366.2 224.4 345.8 224.4C326.4 224.5 313.5 215.1 308.3 200.8Z"
				fill="white"
			/>
			<path
				d="M390.1 135.2C390.1 129.3 395 124.3 400.9 124.3C407 124.3 411.8 129.3 411.8 135.2C411.8 141.1 407 146 400.9 146C395 146 390.1 141.1 390.1 135.2ZM392.1 154.2H409.7V222.5H392.1V154.2Z"
				fill="white"
			/>
			<path
				d="M449.5 171.2V199.6C449.5 207 454.8 207.2 465 206.6V222.5C440.3 225.2 431.9 217.9 431.9 199.6V171.2H420.1V154.2H431.9V140.4L449.5 135.1V154.2H465V171.2H449.5Z"
				fill="white"
			/>
			<path
				d="M509.5 208.5C516.3 208.5 521.8 205.6 524.8 201.7L539.1 209.9C532.7 219.2 522.4 224.5 509.3 224.5C486.4 224.5 471.8 208.7 471.8 188.4C471.8 168.1 486.4 152.3 507.9 152.3C528.1 152.3 542.5 168.2 542.5 188.4C542.5 191 542.2 193.3 541.7 195.7H490.2C492.8 204.6 500.2 208.5 509.5 208.5ZM524.8 182C522.7 172.1 515.2 168.2 507.8 168.2C498.4 168.2 492 173.2 489.9 182H524.8Z"
				fill="white"
			/>
			<path
				d="M552.7 135.2C552.7 129.3 557.6 124.3 563.5 124.3C569.6 124.3 574.4 129.3 574.4 135.2C574.4 141.1 569.6 146 563.5 146C557.6 146 552.7 141.1 552.7 135.2ZM554.7 154.2H572.3V222.5H554.7V154.2Z"
				fill="white"
			/>
			<path
				d="M690 180.4V222.5H672.4V181.7C672.4 173.7 668.3 168.6 660.9 168.6C653.2 168.6 648.1 173.8 648.1 184.1V222.5H630.5V181.7C630.5 173.7 626.4 168.6 619 168.6C611.4 168.6 606.1 173.8 606.1 184.1V222.5H588.5V154.2H606V161.5C609.8 155.7 616.3 152.4 625 152.4C633.5 152.4 639.7 155.9 643.5 162.3C647.8 156.1 654.6 152.4 663.8 152.4C679.7 152.4 690 163.3 690 180.4Z"
				fill="white"
			/>
			<path
				d="M778.2 188.3C778.2 208.6 763.2 224.4 744.8 224.4C734.8 224.4 727.6 220.8 722.6 214.4V249.8H705V154.2H722.6V162.2C727.5 156 734.7 152.2 744.8 152.2C763 152.4 778.2 168 778.2 188.3ZM760.4 188.3C760.4 176.8 752.4 169 741.4 169C730.6 169 722.6 176.7 722.6 188.3C722.6 199.9 730.6 207.6 741.4 207.6C752.4 207.6 760.4 199.9 760.4 188.3Z"
				fill="white"
			/>
			<path
				d="M828.9 152.8V172.5C819.8 171.4 808.1 175.5 808.1 189.8V222.4H790.5V154.2H808.1V165.9C811.7 156.6 820.4 152.8 828.9 152.8Z"
				fill="white"
			/>
			<path
				d="M833 188.3C833 168 848.9 152.2 869.1 152.2C889.1 152.2 905.4 168 905.4 188.3C905.4 208.6 889.2 224.4 869.1 224.4C849.1 224.5 833 208.7 833 188.3ZM887.8 188.3C887.8 177.2 879.8 169.5 869.3 169.5C859 169.5 850.8 177.2 850.8 188.3C850.8 199.4 858.8 207.1 869.3 207.1C879.6 207.2 887.8 199.4 887.8 188.3Z"
				fill="white"
			/>
			<path
				d="M979.5 154.2L953.5 222.5H933.5L907.5 154.2H927L943.5 202L960.2 154.2H979.5Z"
				fill="white"
			/>
			<path
				d="M1019.4 208.5C1026.2 208.5 1031.7 205.6 1034.7 201.7L1049 209.9C1042.6 219.2 1032.3 224.5 1019.2 224.5C996.3 224.5 981.7 208.7 981.7 188.4C981.7 168.1 996.3 152.3 1017.8 152.3C1038 152.3 1052.4 168.2 1052.4 188.4C1052.4 191 1052.1 193.3 1051.6 195.7H1000.1C1002.6 204.6 1010 208.5 1019.4 208.5ZM1034.6 182C1032.5 172.1 1025 168.2 1017.6 168.2C1008.2 168.2 1001.8 173.2 999.7 182H1034.6Z"
				fill="white"
			/>
			<path
				d="M167.2 63.2C105.8 63.2 56 113 56 174.4C56 235.8 105.7 285.5 167.1 285.5C228.5 285.5 278.2 235.8 278.2 174.4C278.2 113 228.5 63.2 167.2 63.2ZM148.9 224.4C129.5 224.5 116.6 215.1 111.4 200.9L127.5 191.5C131 200.5 137.7 206.1 149.5 206.1C161.3 206.1 164.8 201.2 164.8 195.9C164.8 188.8 158.4 186 144.2 181.9C129.6 177.7 115.4 171.6 115.4 153.4C115.4 135.2 130.4 125 146.8 125C163.2 125 174.6 133 180.9 146.4L165.1 155.5C161.6 148.2 156.5 143.2 146.7 143.2C138.8 143.2 134.1 147.3 134.1 152.8C134.1 158.7 137.7 161.8 152.3 166.2C167.5 171.1 183.6 176.1 183.6 195.6C183.6 213.4 169.2 224.4 148.9 224.4ZM212.8 222.5H195.2V154.2H212.8V222.5ZM204 146.1C197.9 146.1 193.2 141.2 193.2 135.3C193.2 129.4 198.1 124.4 204 124.4C209.9 124.4 214.9 129.4 214.9 135.3C214.9 141.2 210 146.1 204 146.1Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4153_96768">
				<rect width="997.5" height="225.2" fill="white" transform="translate(55 62)" />
			</clipPath>
		</defs>
	</svg>
);

const initialsStyle = {
	backgroundColor: "var(--color--status--neutral--light)",
	width: "var(--space--xx-large)",
	height: "var(--space--xx-large)",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "50%",
	color: "var(--color--blue--dark)",
	fontFamily: "var(--font-family--heading)",
	fontSize: "var(--font-size--x-small)",
	fontWeight: "var(--font-weight--bold)",
	lineHeight: "var(--line-height--large)",
	letterSpacing: "0.0375rem",
};

const BasicUsage = () => (
	<HorizontalNavigation
		appLogo={{
			icon: siteImproveLogo,
			href: "https://my2.siteimprove.com",
			alt: "Go to main page",
			"data-observe-key": "top-nav-company-link",
		}}
		actions={[
			{
				items: [
					{
						text: "Users",
						onClick: () => console.log("clicked"),
						"data-observe-key": "top-nav-users",
					},
					ActionMenu.divider,
					{ text: "Sites", href: "https://siteimprove.com", "data-observe-key": "top-nav-sites" },
					{ text: "Groups", href: "https://siteimprove.com", "data-observe-key": "top-nav-groups" },
					ActionMenu.divider,
					{ text: "Report", href: "https://siteimprove.com", "data-observe-key": "top-nav-report" },
					ActionMenu.divider,
					{
						text: "Subscriptions",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-subscriptions",
					},
					ActionMenu.divider,
					{
						text: "All settings",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-all-settings",
					},
				],
				buttonContent: (
					<Icon>
						<IconSettings />
					</Icon>
				),
				"data-observe-key": "top-nav-settings",
				"aria-label": "Settings",
				tooltip: "Settings",
			},
			{
				items: [
					{
						text: "Help Center",
						onClick: () => console.log("clicked"),
						"data-observe-key": "top-nav-help-center",
					},
					ActionMenu.divider,
					{
						text: "Frontier",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-frontier",
					},
					ActionMenu.divider,
					{
						text: "Explore more modules",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-explore-more-modules",
					},
				],
				buttonContent: (
					<Icon>
						<IconHelp />
					</Icon>
				),
				"data-observe-key": "top-nav-help",
				"aria-label": "Help and training",
				tooltip: "Help and training",
			},
			{
				items: [
					{
						text: "User Name",
						onClick: () => console.log("clicked"),
						icon: (
							<span style={initialsStyle} aria-hidden="true">
								UN
							</span>
						),
						"data-observe-key": "top-nav-user-name",
					},
					ActionMenu.divider,
					{
						text: "Switch account",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-switch-account",
					},
					{
						text: "Sign out",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-sign-out",
					},
				],
				buttonContent: (
					<Icon>
						<IconUser />
					</Icon>
				),
				"data-observe-key": "top-nav-profile",
				"aria-label": "Profile",
				tooltip: "Profile",
			},
		]}
		data-observe-key="top-nav"
		skipToContentId="properties"
	/>
);
