import React from "react";
import { H1 } from "@siteimprove/fancylib";
import { BreadcrumbProps, Breadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import {
	PageHeaderUtilities,
	PageHeaderUtilitiesProps,
} from "../page-header-utilities/page-header-utilities";
import { PageHeaderPickers } from "../page-header-pickers/page-header-pickers";
import scss from "./page-header.scss";

type PageHeaderProps = {
	/** Page title  */
	title: React.ReactNode;
	/** Breadcrumbs to be displayed for navigation  */
	breadcrumbs?: BreadcrumbProps;
	/** Utilities and actions for user interaction  */
	utilities?: PageHeaderUtilitiesProps;
	/** Page pickers  */
	pickers?: { items: React.ReactNode | React.ReactNode[]; loading?: boolean };
};

export function PageHeader(props: PageHeaderProps): JSX.Element {
	const { title, breadcrumbs, utilities, pickers } = props;
	return (
		<header>
			<div className={scss.container}>
				<div className={scss.containerHeader}>
					{breadcrumbs && (
						<div className={scss.breadcrumbs}>
							<Breadcrumbs
								items={breadcrumbs.items}
								aria-label={breadcrumbs["aria-label"]}
								noPadding
							/>
						</div>
					)}
					<div className={scss.titleAndUtilities}>
						{makeTitle(title)}
						{utilities && (
							<PageHeaderUtilities
								productSpecific={utilities.productSpecific}
								global={utilities.global}
							/>
						)}
					</div>
				</div>
			</div>
			{pickers && (
				<PageHeaderPickers
					loading={pickers.loading}
					items={pickers.items}
					className={scss.pickers}
				/>
			)}
		</header>
	);
}

function makeTitle(title: React.ReactNode): React.ReactNode {
	return typeof title === "string" ? <H1>{title}</H1> : title;
}
