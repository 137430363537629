import React from "react";
import {
	Badge,
	H2,
	LinkContainer,
	Paragraph,
	TextContainer,
	Ul,
	useUniqueId,
} from "@siteimprove/fancylib";
import {
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	Code,
	Header,
	ImportExample,
	Example,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionList, LinkItem } from "./action-list";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Action list",
	notepad: "",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Action list"
			subTitle="List-content of the ActionMenu, that can be used independently."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ActionList} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The inner list content of the <Code>ActionMenu</Code> component.
				</Paragraph>
				<Header.H3>Basic usage</Header.H3>
				<Example fn={BasicUsage} />
				<Header.H3>Selected usage</Header.H3>
				<Example fn={SelectedUsage} />
				<Header.H3>Custom renderer usage</Header.H3>
				<Example fn={CustomRendererUsage} />
				<Header.H3>Pagination Usage</Header.H3>
				<Example fn={PaginationUsage} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={["...", "..."]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<ActionList
		items={[
			{ text: "Actionbutton", onClick: () => console.log("clicked") },
			{ text: "ActionLink", href: "https://www.google.com", openNew: true },
			ActionList.divider,
			{
				text: "Disabled actionbutton",
				onClick: () => console.log("clicked"),
				disabled: true,
			},
			ActionList.sectionHeader("New section"),
			{
				text: "Actionbutton",
				onClick: () => console.log("clicked"),
			},
		]}
	/>
);

const SelectedUsage = () => (
	<ActionList
		items={[
			{ text: "Actionbutton", onClick: () => console.log("clicked") },
			{ text: "Actionbutton", onClick: () => console.log("clicked"), selected: true },
			{ text: "ActionLink", href: "https://www.google.com", openNew: true },
		]}
	/>
);

const CustomRendererUsage = () => {
	const items = [
		{
			text: "First link",
			href: "#first",
			count: 1,
		},
		{
			text: "Second link",
			href: "#second",
			count: 2,
		},
	];

	return (
		<ActionList
			items={items}
			itemRenderer={(props) => {
				const item = props.item as LinkItem & { count: number };
				return (
					<LinkContainer
						tabIndex={-1}
						href={item.href}
						style={{
							width: "100%",
							padding: "10px",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<LinkContainer.LinkText>{item.text}</LinkContainer.LinkText>
						<Badge>{item.count}</Badge>
					</LinkContainer>
				);
			}}
		/>
	);
};

const PaginationUsage = () => {
	const id = useUniqueId("interactive-list");
	const items = [];
	for (let i = 0; i < 100; i++) {
		items.push({ text: `Item ${i + 1}`, onClick: () => console.log(`clicked item ${i + 1}`) });
	}

	return (
		<>
			<H2 id={id}>Title of list</H2>
			<ActionList
				items={items}
				pageChanger={{
					pageSize: 5,
					confirmLabel: "Confirm",
					cancelLabel: "Cancel",
					firstLabel: "First",
					prevLabel: "Previous",
					nextLabel: "Next",
					lastLabel: "Last",
					pageLabel: "Page",
					pageXofYLabel: (current, total) => `Page ${current} of ${total}`,
					defaultError: "Default error",
					wholeNumberError: "Whole number error",
					outOfBoundsError: (total) => `Out of bounds error. Total is ${total}`,
				}}
			/>
		</>
	);
};
